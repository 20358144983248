import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { getTransactionsByType as getPaytoraTransactions } from '../../services/transactions';

import TransactionItem from './transactionItem';
import { Header } from '../navigation/header';
import { PrimaryButton } from '../buttons/primary-button';

import { ArrowBottomRightIcon } from '@radix-ui/react-icons';
import { ArrowTopRightIcon } from '@radix-ui/react-icons';

export default function PayToraTransactions() {
	const navigate = useNavigate();

	const [transactions, setTransactions] = useState([]);

	const { currency } = useParams();

	const query = new URLSearchParams(window.location.search);

	const balance = query.get('balance');

	const currencySymbol = currency === 'USD' ? '$' : '€';

	const getBalanceColor = () => {
		const color = currency === 'USD' ? 'bg-green-500' : currency === 'EUR' ? 'bg-blue' : 'bg-gray-500';
		return color;
	};

	const fetchTransactions = async () => {
		const transactions = await getPaytoraTransactions(currency);

		if (transactions.isSuccess) setTransactions(transactions.data);
	};

	useEffect(() => {
		fetchTransactions();
	}, []);

	return (
		<div>
			<Header />

			<div className="flex justify-center mb-10">
				<PrimaryButton
					text="Deposit Money"
					onClick={() => navigate('/top-up/other/select/' + currency)}
					className="p-10 me-5"
					icon={<ArrowBottomRightIcon className="mt-[7px] ms-2" />}
				/>
				<PrimaryButton
					text="Send Money"
					onClick={() => navigate('/transfer-request/member/' + currency)}
					className="p-10"
					icon={<ArrowTopRightIcon className="mt-2 ms-2" />}
				/>
			</div>

			<div className="flex flex-col items-center mt-5">
				<div>
					<div className="flex items-center ms-5 mb-2 font-bolder">
						<div className={`${getBalanceColor()} me-3 w-10 h-10 rounded-full flex items-center justify-center text-white text-xl`}>
							{currencySymbol}
						</div>
						Paytora {currencySymbol} Account Balance
					</div>
					<div className={`${getBalanceColor()} rounded-3xl p-2 text-center font-bolder text-white`}>{currencySymbol + balance} </div>
				</div>
			</div>
			{transactions.length > 0 ? (
				transactions.map((transaction) => <TransactionItem transaction={transaction} />)
			) : (
				<p className="text-center mt-10">You don't have any transactions yet</p>
			)}
		</div>
	);
}
