import { useEffect, useState } from 'react';

import TransactionItem from './transactionItem';

import { Header } from '../navigation/header';

import { PrimaryButton } from '../buttons/primary-button';
import { PincodeModal } from '../modals/pincodeModal';

import { getCardTransactions } from '../../services/transactions';
import { getUserCardsAPI } from '../../services/cards';
import { setUserPincode, verifyUserPincode } from '../../services/pincode';

import { CreditCardItem } from '../credit-card/credit-card-item';
import Loader from '../../pages/utils/loader';
import { getUserSpecificCardAPI } from '../../services/cards';

import { CustomModal } from '../utils/modal';

import TopUpModal from '../modals/topupModal';

export default function CardTransactions() {
	const hasPincode = localStorage.getItem('hasPincode');

	const [isLoading, setIsLoading] = useState(false);

	const [transactions, setTransactions] = useState([]);

	const [selectedCard, setSelectedCard] = useState(null);

	const [cards, setCards] = useState([]);

	const [cardBalance, setCardBalance] = useState(0);

	const [isPincodeModalOpen, setIsPincodeModalOpen] = useState(false);

	const [isTopUpModalOpen, setIsTopUpModalOpen] = useState(false);

	const handleCardTransactions = async (cardId) => {
		setSelectedCard(cardId);
		if (!isLoading) {
			setIsLoading(true);
			const response = await getUserSpecificCardAPI(cardId);

			if (response.isSuccess) setCardBalance(response.balance);

			const transactions = await getCardTransactions(cardId);
			if (transactions.isSuccess) setTransactions(transactions.data);

			setIsLoading(false);
		}
	};

	const handlePincodeSubmit = async (pincode) => {
		if (hasPincode === 'true') {
			const response = await verifyUserPincode(pincode);

			if (response.isSuccess) {
				setIsTopUpModalOpen(true);
				setIsPincodeModalOpen(false);
			} else return { error: response.error };
		} else {
			const response = await setUserPincode(pincode);

			if (!response.isSuccess) return { error: response.error };

			setIsPincodeModalOpen(false);
			localStorage.setItem('hasPincode', true);
		}
	};

	const fetchCards = async () => {
		setIsLoading(true);

		const response = await getUserCardsAPI();

		setCards(response.cards);

		setIsLoading(false);
	};

	useEffect(() => {
		fetchCards();
	}, []);

	return (
		<div>
			<Header />

			{selectedCard ? (
				<div>
					<div className="flex justify-center mb-10">
						<PrimaryButton text="Deposit To Card" onClick={() => setIsPincodeModalOpen(true)} className="p-10 me-5" />
					</div>

					<div className="flex flex-col items-center mt-5">
						<div className="">
							<div className="flex items-center ms-5 mb-2 font-bolder">
								<div className={`bg-purple me-3 w-10 h-10 rounded-full flex items-center justify-center text-white text-xl`}>€</div>
								Your Card Balance
							</div>
							<div className={`bg-purple rounded-3xl p-2 text-center font-bolder text-white`}>€{cardBalance} </div>
						</div>
					</div>
					{transactions.length > 0 ? (
						transactions.map((transaction) => <TransactionItem transaction={transaction} key={transaction._id} />)
					) : (
						<p className="text-center mt-10">You don't have any transactions yet</p>
					)}
				</div>
			) : isLoading ? (
				<Loader />
			) : (
				<div className="flex flex-col items-center justify-center">
					<div className="grid grid-cols-1 xl:w-[50%] sm:grid-cols-2 gap-4 w-full overflow-x-auto">
						{cards &&
							cards.length > 0 &&
							cards.map((card) => (
								<CreditCardItem
									key={card._id}
									card={card}
									cardActionsModalHandler={() => {
										handleCardTransactions(card._id);
									}}
									physicalCardButtonText="Show Transactions"
									virtualCardButtonText="Show Transactions"
								/>
							))}
					</div>
					{cards.length === 0 && <p className="">You didn't applied for any card yet.</p>}
				</div>
			)}

			<PincodeModal
				isOpen={isPincodeModalOpen}
				onClose={() => setIsPincodeModalOpen(false)}
				onAction={async (pincode) => await handlePincodeSubmit(pincode)}
				buttonText={hasPincode === 'true' ? 'Verify' : 'Set'}
			/>

			<CustomModal
				isOpen={isTopUpModalOpen}
				onClose={() => setIsTopUpModalOpen(false)}
				children={
					isLoading ? (
						<Loader />
					) : (
						<TopUpModal
							cardId={selectedCard}
							closeHandler={() => {
								setIsTopUpModalOpen(false);
							}}
						/>
					)
				}
			/>
		</div>
	);
}
