import React, { useEffect, useState } from 'react';

import Modal from 'react-modal';

import useValidation from '../../hooks/useValidation';

import Loader from '../../pages/utils/loader';

import { CountriesList } from '../../utils/constants/countries-list';
import { physicalCardApplySchema } from '../../utils/validations/user-schema';

import { PrimaryButton } from '../buttons/primary-button';

import { Input } from '../inputs/text-input';
import SelectInput from '../inputs/select-input';

import { getFeeByType } from '../../services/fees';

export const PhysicalCardApplyModal = ({ isOpen, onClose, onCardApplySubmit, cardPrice }) => {
	const [errMsg, setErrMsg] = useState('');
	const [isLoading, setIsLoading] = useState('');

	const { validationErrMsg, validate } = useValidation(physicalCardApplySchema);

	const [shippingFee, setShippingFee] = useState(0);

	const customStyles = {
		content: {
			top: '50%',
			left: '50%',
			right: 'auto',
			bottom: 'auto',
			marginRight: '-50%',
			transform: 'translate(-50%, -50%)',
			width: '400px', // Adjust the width as needed
			padding: '20px',
			maxHeight: '95vh', // Ensure the modal does not exceed the viewport height
			overflow: 'auto', // Add scroll if content overflows
		},
	};

	const shippingMethodOptions = [
		{ value: 'basic', label: 'Basic (30 days)' },
		{ value: 'standard', label: 'Standard (15 days)' },
		{ value: 'fast', label: 'Fast (5-7 days)' },
	];

	const [formData, setFormData] = useState({
		agreeToTerms: false,
		agreeToPrivacyPolicy: false,
		city: '',
		street: '',
		number: '',
		postalCode: '',
		country: CountriesList[0],
		method: shippingMethodOptions[0].value,
		// pin: '',
	});

	useEffect(() => {
		const fetchData = async () => {
			const feesResponse = await getFeeByType({ type: 'shipping' });
			if (feesResponse.isSuccess) setShippingFee(feesResponse.data.shippingCost[formData.method]);
		};

		fetchData();
	}, [formData.method]);

	const handleCheckboxChange = (fieldName) => {
		setFormData((data) => ({ ...data, [fieldName]: !data[fieldName] }));
	};

	return (
		<Modal
			isOpen={isOpen}
			onRequestClose={onClose}
			contentLabel={'Apply for a new physical card'}
			ariaHideApp={false} // This is to prevent a warning in the console
			style={customStyles}
			shouldCloseOnOverlayClick={false}
		>
			{isLoading ? (
				<Loader className="h-[10rem]" />
			) : (
				<div>
					<h2 className="text-2xl text-center">{'Apply for a new physical card'}</h2>
					<p className="text-center text-red-500">You will be charged for {cardPrice}</p>
					<p className="text-center mt-2 mb-2">(Where you want the card to be delivered)</p>
					<div>
						<div className="inputs-container">
							<SelectInput
								defaultValue={{ value: CountriesList[0], label: CountriesList[0] }}
								onChange={(value) => {
									setFormData((data) => ({
										...data,
										country: value,
									}));
								}}
								options={CountriesList.map((opt) => ({
									value: opt,
									label: opt,
								}))}
								placeHolder="Country"
							/>
							<Input
								onChange={(value) => {
									setFormData((data) => ({ ...data, city: value }));
								}}
								placeholder="City"
								type="text"
								value={formData.city}
								className="bg-secondary border-2 border-black inline-block text-black placeholder:text-black"
							/>
							<Input
								onChange={(value) => {
									setFormData((data) => ({ ...data, street: value }));
								}}
								placeholder="Street"
								type="text"
								value={formData.street}
								className="bg-secondary border-2 border-black inline-block text-black placeholder:text-black"
							/>
							<Input
								onChange={(value) => {
									setFormData((data) => ({ ...data, number: value }));
								}}
								placeholder="Apartment/House Number"
								type="text"
								value={formData.number}
								className="bg-secondary border-2 border-black inline-block text-black placeholder:text-black"
							/>
							<Input
								onChange={(value) => {
									setFormData((data) => ({ ...data, postalCode: value }));
								}}
								placeholder="ZipCode"
								type="text"
								value={formData.postalCode}
								className="bg-secondary border-2 border-black inline-block text-black placeholder:text-black"
							/>

							<SelectInput
								defaultValue={shippingMethodOptions[0]}
								onChange={(value) => {
									setFormData((data) => ({
										...data,
										method: value,
									}));
								}}
								options={shippingMethodOptions.map((opt) => ({
									value: opt.value,
									label: opt.label,
								}))}
								placeHolder="Shipping Method"
							/>

							{/* <Input
								onChange={(value) => {
									if (/^\d*$/.test(value) && value.length <= 4) {
										setFormData((data) => ({ ...data, pin: value }));
									}
								}}
								placeholder="Pin"
								type="text"
								value={formData.pin}
								className="bg-secondary border-2 border-black inline-block text-black placeholder:text-black"
							/>
							<p className="text-center mb-5 text-primary">* Please select a pin that you remember to use it for future card usages!</p> */}
						</div>
						<div className="flex items-center mt-3">
							<input type="checkbox" id="termsCheckbox" checked={formData.agreeToTerms} onChange={() => handleCheckboxChange('agreeToTerms')} />
							<label htmlFor="termsCheckbox" className="ml-2">
								I agree to the
								<span className="text-primary cursor-pointer m-2" onClick={() => window.open('/card/terms', '_blank')}>
									Terms and Conditions
								</span>
							</label>
						</div>

						<div className="flex items-center mt-3 mb-3">
							<input
								type="checkbox"
								id="privacyCheckbox"
								checked={formData.agreeToPrivacyPolicy}
								onChange={() => handleCheckboxChange('agreeToPrivacyPolicy')}
							/>
							<label htmlFor="privacyCheckbox" className="ml-2">
								I agree to the
								<span className="text-primary cursor-pointer m-2" onClick={() => window.open('/card/privacy', '_blank')}>
									Privacy Policy
								</span>
							</label>
						</div>

						<p className="text-red-500 text-center">
							{errMsg}
							<br />
							{validationErrMsg}
							<br />
							{`You will be charged for additional €${shippingFee} for shipping`}
						</p>

						<div className="flex justify-center mt-10">
							<PrimaryButton
								text={'Apply'}
								onClick={async () => {
									const isValid = validate(formData);
									if (!isValid) {
										setErrMsg('');
										return;
									}

									if (!isLoading) {
										setIsLoading(true);

										const response = await onCardApplySubmit(formData);

										if (response && response.error) setErrMsg(response.error);
										setIsLoading(false);
									}
								}}
								className="p-5 me-2"
							/>
							<PrimaryButton onClick={onClose} text="Close" className="p-5" />
						</div>
					</div>
				</div>
			)}
		</Modal>
	);
};
