import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import Median from 'median-js-bridge';

import useValidation from '../../hooks/useValidation';

import Loader from '../utils/loader';
import { loginSchema } from '../../utils/validations/user-schema';

import { Input } from '../../components/inputs/text-input';
import { PrimaryButton } from '../../components/buttons/primary-button';
import { SecondaryButton } from '../../components/buttons/secondary-button';
import IntercomNonUserChat from '../../components/chats/intercom_non_user_chat';
import PasswordInput from '../../components/inputs/password-input';
import LanguageSwitcher from '../../components/utils/languageSwitcher';

import { login } from '../../services/auth';

import { useTranslation } from 'react-i18next';

export default function Login() {
	const navigate = useNavigate();

	const { t } = useTranslation();

	const [username, setUsername] = useState('');
	const [password, setPassword] = useState('');

	const { validationErrMsg, validate } = useValidation(loginSchema(t));

	const [apiErrorMsg, setApiErrorMsg] = useState('');

	const [isLoading, setIsLoading] = useState(false);

	const [isNative, setIsNative] = useState(false);

	const [hasTouchId, setHasTouchId] = useState(false);

	const [hasSecert, setHasSecret] = useState(false);

	const medianCheckBio = async () => {
		//minimumAndroidBiometric: 'strong' | 'weak', // optional, default is 'strong'

		await Median.auth.status().then((data) => {
			if (isNative && data) {
				setHasTouchId(data.hasTouchId);
				setHasSecret(data.hasSecret);
			}
		});
	};

	const medianBioLogin = async () => {
		await Median.auth.status().then((data) => {
			if (data.hasTouchId && data.hasSecret) {
				Median.auth.get().then(async (data) => {
					if (data && data.success && data.secret) {
						const credentials = JSON.parse(data.secret);
						const username = credentials.username;
						const password = credentials.password;

						if (!isLoading) {
							setIsLoading(true);

							const response = await login(username, password);

							if (!response.isSuccess) {
								setApiErrorMsg(t(`login.serverErrors.${response.error}`));
							} else {
								setApiErrorMsg('');
								navigate(0);
							}
							setIsLoading(false);
						}
					}
				});
			}
		});
	};

	useEffect(() => {
		setIsNative(Median.isNativeApp());
		medianCheckBio();
	});

	const handleSubmit = async (event) => {
		event.preventDefault();

		const isValid = validate({ email: username, password });
		if (!isValid) return;

		if (!isLoading) {
			setIsLoading(true);

			const response = await login(username, password);

			if (!response.isSuccess) {
				setApiErrorMsg(t(`login.serverErrors.${response.error}`));
			} else {
				if (hasTouchId && isNative) {
					const secret = JSON.stringify({
						username: username,
						password: password,
					});

					await Median.auth.save({
						secret: secret,
						// minimumAndroidBiometric: 'strong',
					});
				}
				setApiErrorMsg('');
				navigate(0);
			}
			setIsLoading(false);
		}
	};

	return isLoading ? (
		<Loader />
	) : (
		<div>
			{/* <div className="flex justify-end">
				<LanguageSwitcher />
			</div> */}
			<form onSubmit={handleSubmit}>
				<div className="flex flex-col items-center mt-10 p-5">
					<img src="/assets/logo.png" alt="logo" className="xsm:w-full w-1/3 mt-10 mb-10" />
					<Input
						onChange={(value) => {
							setUsername(value);
						}}
						placeholder={t('login.emailPlaceholder')}
						type="text"
						value={username}
						className="text-white placeholder:text-white"
					/>
					<PasswordInput value={password} onChange={setPassword} placeholder={t('login.passwordPlaceholder')} />

					{validationErrMsg && <div className="text-error mb-5 font-bold">{validationErrMsg}</div>}
					{apiErrorMsg && <div className="text-error mb-5 font-bold">{apiErrorMsg}</div>}

					<PrimaryButton text={t('login.loginButton')} className={'p-10 mb-5'} />
					{hasTouchId && hasSecert && <SecondaryButton text={t('login.bioLoginButton')} className={'p-10'} onClick={() => medianBioLogin()} />}
					<SecondaryButton text={t('login.signupButton')} className={'p-5 mt-3 mb-3'} onClick={() => navigate('/register')} />
					<a href="/forgot-password">{t('login.forgotPassword')}</a>
				</div>
			</form>
			<IntercomNonUserChat />
		</div>
	);
}
