import { Header } from '../../components/navigation/header';

export default function NotFound404() {
	return (
		<div>
			<Header showUser={false} showBalance={false} showBack={false} />
			<div className="min-h-screen bg-green404 flex justify-center items-center flex-col">
				<img alt="404" src="/assets/logo.png" className="w-64 mt-10"></img>
				<img alt="404" src="/assets/404.jpeg" className="h-screen w-auto"></img>
			</div>
		</div>
	);
}
