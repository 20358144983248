import { useState } from 'react';
import { decodeToken } from 'react-jwt';

import { Header } from '../../components/navigation/header';
import { PrimaryButton } from '../../components/buttons/primary-button';
import { Input } from '../../components/inputs/text-input';

import { updateUserPassword } from '../../services/user';

export default function UserProfile() {
	const [currentPassword, setCurrentPassword] = useState('');
	const [newPassword, setNewPassword] = useState('');
	const [errMsg, setErrMsg] = useState('');
	const [isLoading, setIsLoading] = useState(false);

	const token = localStorage.getItem('token');
	const decodedToken = decodeToken(token);
	const fullname = decodedToken.firstName + ' ' + decodedToken.lastName;

	const updatePassword = async () => {
		if (!currentPassword || !newPassword) {
			setErrMsg('Please fill the required details.');
			return;
		}
		if (!isLoading) {
			setIsLoading(true);
			const response = await updateUserPassword({ oldPassword: currentPassword, newPassword });
			if (!response.isSuccess) {
				setErrMsg('Please make sure you write the right password, password should include 8 letters include capital (A a) and a symbol ($%!@)');
				setIsLoading(false);
				return;
			}
			localStorage.clear();
			window.location.reload();
		}
		setIsLoading(false);
	};

	return (
		<div className="text-center">
			<Header />
			<h1 className="text-3xl mb-10">User Profile</h1>
			<div className="text-center flex flex-col items-center p-5">
				<p className="text-xl mb-5">Welcome {fullname}</p>
				<form>
					<Input
						onChange={(value) => {
							setCurrentPassword(value);
						}}
						placeholder="Current Password"
						type="password"
						value={currentPassword}
						className="text-white placeholder:text-white"
					/>
					<Input
						onChange={(value) => {
							setNewPassword(value);
						}}
						placeholder="New Password"
						type="password"
						value={newPassword}
						className="text-white placeholder:text-white"
					/>
				</form>
				<p className="text-red-500 font-bold mb-5">{errMsg}</p>
				<div className="flex flex-col items-center justify-center mb-5">
					<PrimaryButton text="Change Password" className="p-5" onClick={updatePassword} />
				</div>
			</div>
		</div>
	);
}
