import { useRef } from 'react';
import { Input } from './text-input';

export const PincodeInput = ({ onChange, pincode, className }) => {
	const inputRefs = useRef(Array(6).fill(null));

	const handlePincodeChange = (index, value) => {
		// Handle Backspace key to clear value and move focus
		if (value === '' && index > 0) {
			// Clear the current input
			const newPincodeValues = [...pincode];
			newPincodeValues[index] = '';
			onChange(newPincodeValues);

			// Move focus to the previous input
			inputRefs.current[index - 1]?.focus();
			return;
		}

		// Ensure only numbers and length <= 1
		if (/^\d*$/.test(value) && value.length <= 1) {
			// Create a new array with updated value at the given index
			const newPincodeValues = [...pincode];
			newPincodeValues[index] = value;
			onChange(newPincodeValues);

			// Move focus to the next input or blur if deleting
			if (value.length === 1 && index < pincode.length - 1) {
				inputRefs.current[index + 1]?.focus();
			} else if (value.length === 0 && index > 0) {
				inputRefs.current[index - 1]?.focus();
			}
		}
	};
	const handleKeyPress = (e, index) => {
		if (e.key === 'ArrowLeft' || e.key === 'ArrowRight' || e.key === 'ArrowUp' || e.key === 'ArrowDown') {
			return;
		}
		// Prevent input if not a number
		if (!/^\d$/.test(e.key)) {
			e.preventDefault();
		}

		// Handle backspace to go to previous input
		if (e.key === 'Backspace' && index >= 0) {
			handlePincodeChange(index, ''); // Clear the value
			inputRefs.current[index - 1]?.focus();
		}
	};
	return (
		<div className="flex justify-center items-center">
			{pincode.map((value, index) => (
				<Input
					key={index}
					ref={(ref) => (inputRefs.current[index] = ref)}
					type="number"
					value={value}
					onChange={(val) => handlePincodeChange(index, val)}
					onKeyDown={(e) => handleKeyPress(e, index)}
					className={`w-10 h-10 mx-1 text-center ${className}`}
					maxLength={1}
					inputMode="numeric"
					pattern="[0-9]"
				/>
			))}
		</div>
	);
};
