import { apiRequest } from './api';

export async function getUserCardsBalanceAPI() {
	try {
		const response = await apiRequest('/user/cards/balances', 'GET', null);

		return { isSuccess: true, balance: response.data };
	} catch (error) {
		return { isSuccess: false, error: error.message };
	}
}

export async function getUserSpecificCardAPI(cardId) {
	try {
		const response = await apiRequest('/user/card/balance/' + cardId, 'GET', null);

		return { isSuccess: true, balance: response.data };
	} catch (error) {
		return { isSuccess: false, error: error.message };
	}
}

export async function getUserCardsAPI() {
	try {
		const response = await apiRequest('/user/cards', 'GET', null);

		return { isSuccess: true, cards: response.data.cards, templates: response.data.templates };
	} catch (error) {
		return { isSuccess: false, error: error.message };
	}
}

export async function getUserCardData(cardId) {
	try {
		const response = await apiRequest('/user/card/show/' + cardId, 'GET', null);

		return { isSuccess: true, card: response.data };
	} catch (error) {
		return { isSuccess: false, error: error.message };
	}
}

export async function cardApplyAPI(cardId, data = null) {
	try {
		await apiRequest(`/user/card/apply/${cardId}`, 'POST', data);
		return { isSuccess: true };
	} catch (error) {
		return { isSuccess: false, error: error.message };
	}
}

export async function topUpUserCardAPI(data) {
	try {
		await apiRequest(`/user/card/topup`, 'POST', data);
		return { isSuccess: true };
	} catch (error) {
		return { isSuccess: false, error: error.message };
	}
}

export async function freezeVirtualCardAPI(cardId) {
	try {
		await apiRequest(`/user/card/freeze/${cardId}`, 'PUT', null);
		return { isSuccess: true };
	} catch (error) {
		return { isSuccess: false, error: error.message };
	}
}

export async function unFreezeVirtualCard(cardId) {
	try {
		await apiRequest(`/user/card/unfreeze/${cardId}`, 'PUT', null);
		return { isSuccess: true };
	} catch (error) {
		return { isSuccess: false, error: error.message };
	}
}

export async function terminateVirtualCard(cardId) {
	try {
		await apiRequest(`/user/card/terminate/${cardId}`, 'PUT', null);
		return { isSuccess: true };
	} catch (error) {
		return { isSuccess: false, error: error.message };
	}
}

export async function activatePhysicalCard(cvv, digits) {
	try {
		await apiRequest(`/user/card/activate/`, 'POST', { cvv, digits });
		return { isSuccess: true };
	} catch (error) {
		return { isSuccess: false, error: error.message };
	}
}

export async function showPhysicalCardPin(cardId) {
	try {
		const response = await apiRequest(`/user/card/showPin/${cardId}`, 'GET');
		return { isSuccess: true, data: response.pin };
	} catch (error) {
		return { isSuccess: false, error: error.message };
	}
}
