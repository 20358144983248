import { useNavigate } from 'react-router-dom';

import { Header } from '../../components/navigation/header';

export default function TransferRequest() {
	const navigate = useNavigate();

	return (
		<div>
			<Header />
			<div className="flex flex-col items-center">
				<h1 className="font-bolder text-3xl mb-10 text-center xsm:text-[2rem]">Transfer Funds</h1>
				<div className="w-full flex jutify-center items-center flex-col">
					<div className="flex justify-center p-5">
						<div className="bg-primary rounded-2xl flex justify-center h-48 w-1/2 p-5 me-5">
							<button className="text-white text-2xl text-center" onClick={() => navigate('/transfer-request/member')}>
								To Paytora Member
							</button>
						</div>
						{/* <div className="bg-primary rounded-2xl flex justify-center h-48 w-1/2 p-5">
							<button className="text-white text-2xl text-center" onClick={() => setComingSoonModalOpen(true)}>
								To A Business Merchant
							</button>
						</div> */}
					</div>
					{/* <div className="flex justify-center p-5">
						<div className="bg-primary rounded-2xl flex justify-center h-48 w-1/2 p-5 me-5">
							<button className="text-white text-2xl text-center" onClick={() => setComingSoonModalOpen(true)}>
								From Your IBAN Account
							</button>
						</div>
						<div className="bg-primary rounded-2xl flex justify-center h-48 w-1/2">
							<button className="text-white text-xl text-center" onClick={() => setComingSoonModalOpen(true)}>
								From Your Paytora Account To Your IBAN Account
							</button>
						</div>
					</div> */}
				</div>
			</div>
			{/* <CustomModal
				isOpen={isComingSoonModalOpen}
				onClose={() => setComingSoonModalOpen(false)}
				title={'Will be available soon!'}
				subTitle={'This action will be available soon'}
				children={
					<div className="flex justify-center">
						<PrimaryButton
							onClick={() => {
								setComingSoonModalOpen(false);
							}}
							text="Close"
							className="p-5 mt-5"
						/>
					</div>
				}
			/> */}
		</div>
	);
}
