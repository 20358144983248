import { formatCreditCardNumber } from '../../utils/functions/general';
import numeral from 'numeral';
import { PrimaryButton } from '../buttons/primary-button';
import { EyeOpenIcon } from '@radix-ui/react-icons';
import { useState } from 'react';
import { activatePhysicalCard, getUserCardData } from '../../services/cards';
import { PincodeModal } from '../modals/pincodeModal';
import { setUserPincode, verifyUserPincode } from '../../services/pincode';
import { CustomModal } from '../utils/modal';
import { PincodeInput } from '../inputs/pincodeInput';
import { SecondaryButton } from '../buttons/secondary-button';
import Loader from '../../pages/utils/loader';

export const CreditCardItem = ({
	card,
	cardActionsModalHandler,
	virtualCardButtonText = 'Card Actions',
	physicalCardButtonText = 'Activate Card',
}) => {
	const hasPincode = localStorage.getItem('hasPincode');

	const [showCardData, setShowCardData] = useState(false);

	const [isLoading, setIsLoading] = useState(false);

	const [cardData, setCardData] = useState(null);

	const [isPincodeModalOpen, setIsPincodeModalOpen] = useState(false);

	const [isCardOrderedModalOpen, setIsCardOrderedModalOpen] = useState(false);

	const [isActivationSuccessModalOpen, setIsActivationSuccessModalOpen] = useState(false);

	const [cvvValues, setCvvValues] = useState(Array(3).fill(''));
	const [cardDigitsValues, setCardDigitsValues] = useState(Array(6).fill(''));

	const [errMsg, setErrMsg] = useState('');

	const showCardNumberHandler = async () => {
		if (showCardData) {
			setShowCardData(false);
			return;
		}
		if (!isLoading) {
			setIsLoading(true);
			const response = await getUserCardData(card._id);
			if (response.isSuccess) {
				setCardData(response.card);
				setShowCardData(!showCardData);

				setTimeout(() => {
					setShowCardData(false);
				}, 30000);
			}
		}
		setIsLoading(false);
	};

	const activatePhysicalCardHandler = async () => {
		const cvv = cvvValues.join('');
		const lastDigits = cardDigitsValues.join('');

		if (lastDigits.length < 6 || cvv.length < 3) {
			setErrMsg('Please enter activation card data (CVV and last 6 digits)!');
			return;
		}

		if (!isLoading) {
			setIsLoading(true);
			const response = await activatePhysicalCard(cvv, lastDigits);

			if (!response.isSuccess) {
				setErrMsg(response.error ?? 'An error happend please contact support!');
			} else {
				setIsActivationSuccessModalOpen(true);
			}
		}

		setIsLoading(false);
	};

	const handlePincodeSubmit = async (pincode) => {
		if (hasPincode === 'true') {
			const response = await verifyUserPincode(pincode);

			if (response.isSuccess) {
				await showCardNumberHandler();
				setIsPincodeModalOpen(false);
			} else return { error: response.error };
		} else {
			const response = await setUserPincode(pincode);

			if (!response.isSuccess) return { error: response.error };

			setIsPincodeModalOpen(false);
			localStorage.setItem('hasPincode', true);
		}
	};

	const getCardTypeByLimit = () => {
		if (card.limit === 250000) return { name: 'Infinity', color: 'text-black' };

		if (card.limit === 100000) return { name: 'Gold', color: 'text-orange' };

		return { name: 'Premium', color: 'text-primary' };
	};

	return (
		<div className="p-2 text-gold font-bolder">
			<div className="text-center text-primary font-bold text-lg">
				{card.cardTemplate.type.charAt(0).toUpperCase() + card.cardTemplate.type.slice(1)} Card
				<h2 className={`${getCardTypeByLimit().color}`}> Card Type: {getCardTypeByLimit().name} </h2>
				Balance:
				{numeral(card.balance).format('0,0.00') + card.currency.symbol} | Card Limit:
				{numeral(card.cardTemplate.limit).format('0,0') + card.currency.symbol}
			</div>
			<div
				style={{
					backgroundColor: 'black',
					border: '2px solid gold',
					padding: '20px',
					borderRadius: '10px',
				}}
			>
				<div className="flex justify-between">
					<div className="flex-col flex items-center justify-center">
						<div className="flex">
							<img src="/assets/GooglePay.png" alt="Google Pay" width={50} className="me-5" />
							<img src="/assets/ApplePay.png" alt="Apple Pay" width={50} className="me-5" />
						</div>
						<div className="flex mt-5">
							<img src="/assets/nfc-chip.png" alt="NFC" width={30} className="me-5" />
							<img src="/assets/nfc-gold.png" alt="NFC" className="h-5" />
						</div>
					</div>
					<img src="/assets/card-logo.png" alt="Logo" width={150} />
				</div>

				<div className="flex mt-10">
					<p>{showCardData ? formatCreditCardNumber(cardData.pan) : formatCreditCardNumber(card.pan)}</p>

					{card.status === 'A' && (
						<EyeOpenIcon
							style={{ width: '25px', height: '25px' }}
							className="ms-5"
							onClick={() => {
								if (showCardData) setShowCardData(false);
								else setIsPincodeModalOpen(true);
							}}
						/>
					)}

					{showCardData && <p className="ms-5">CVV: {cardData.cvv}</p>}
				</div>
				<div className="flex justify-between items-center">
					<p>{card.expiration && 'EXP ' + card.expiration}</p>
					<img src="/assets/mastercard-transperant.png" alt="MasterCard" width={50} />
				</div>
			</div>
			<PrimaryButton
				text={card.status === 'P' ? physicalCardButtonText : virtualCardButtonText}
				className="w-full mt-2"
				onClick={card.status === 'P' ? () => setIsCardOrderedModalOpen(true) : cardActionsModalHandler}
			/>

			<CustomModal
				isOpen={isCardOrderedModalOpen}
				onClose={() => setIsCardOrderedModalOpen(false)}
				title={'Your card has been ordered!'}
				subTitle={'You can activate the card when its arrived and you have it in your hand'}
				children={
					isLoading ? (
						<Loader />
					) : (
						<div>
							Enter CVV
							<PincodeInput onChange={(value) => setCvvValues(value)} pincode={cvvValues} className="text-white" />
							Enter Last 6 digits
							<PincodeInput onChange={(value) => setCardDigitsValues(value)} pincode={cardDigitsValues} className="text-white" />
							<div className="flex justify-center">
								<PrimaryButton
									onClick={async () => {
										await activatePhysicalCardHandler();
									}}
									text="Activate"
									className="p-5 mt-5 me-2"
								/>
								<SecondaryButton
									onClick={() => {
										setIsCardOrderedModalOpen(false);
									}}
									text="Close"
									className="p-5 mt-5"
								/>
							</div>
							<p className="text-red-500 text-center mt-5">{errMsg}</p>
						</div>
					)
				}
			/>

			<CustomModal
				isOpen={isActivationSuccessModalOpen}
				onClose={() => setIsActivationSuccessModalOpen(false)}
				title={'Your card has been activated'}
				children={
					<div>
						<div className="flex justify-center">
							<PrimaryButton
								onClick={() => {
									setIsActivationSuccessModalOpen(false);
									window.location.reload();
								}}
								text="Close"
								className="p-5 mt-5"
							/>
						</div>
					</div>
				}
			/>

			<PincodeModal
				isOpen={isPincodeModalOpen}
				onClose={() => setIsPincodeModalOpen(false)}
				onAction={async (pincode) => await handlePincodeSubmit(pincode)}
				buttonText={hasPincode === 'true' ? 'Verify' : 'Set'}
			/>
		</div>
	);
};
