import { useNavigate } from 'react-router-dom';

export const BackButton = ({ disabled, className, navigation }) => {
	const navigate = useNavigate();

	return (
		<button
			disabled={disabled}
			onClick={navigation ? navigation : () => navigate(-1)}
			className={`${
				disabled ? 'bg-gray- first-letter:' : 'bg-secondary'
			} active:scale-[.98] active:duration-75 transition-all hover:scale-[1.01] ease-in-out py-3 rounded-xl m-5 text-primary text-lg font-bold max-w-md p-5 ${className}`}
		>
			Back
		</button>
	);
};
