import Intercom from '@intercom/messenger-js-sdk';

import { jwtDecode } from 'jwt-decode';

import { apiRequest } from './api';

export const login = async (email, password) => {
	try {
		const response = await apiRequest('/auth/login', 'POST', { email, password }, false);

		const decodedToken = jwtDecode(response.token);

		localStorage.setItem('token', response.token);
		localStorage.setItem('kyc', decodedToken.kycLevel);
		localStorage.setItem('sdkToken', decodedToken.SDKToken);
		localStorage.setItem('isSuspended', JSON.stringify(decodedToken.isSuspended));
		localStorage.setItem('hasPincode', !!decodedToken.pincode);
		localStorage.setItem('hasIban', decodedToken.hasIban);

		return { isSuccess: true };
	} catch (error) {
		return { isSuccess: false, error: error.message };
	}
};

export const register = async (type, email) => {
	try {
		await apiRequest('/auth/register', 'POST', { type, email }, false);

		return { isSuccess: true };
	} catch (error) {
		return { isSuccess: false, error: error.message };
	}
};

export const registerConfirm = async (userData) => {
	try {
		const response = await apiRequest('/auth/register/confirm', 'POST', userData, false);

		const decodedToken = jwtDecode(response.token);

		localStorage.setItem('token', response.token);
		localStorage.setItem('kyc', decodedToken.kycLevel);
		localStorage.setItem('sdkToken', decodedToken.SDKToken);

		return { isSuccess: true };
	} catch (error) {
		return { isSuccess: false, error: error.message };
	}
};

export const forgotPassword = async (email) => {
	try {
		await apiRequest('/auth/forgot-password', 'POST', { email }, false);

		return { isSuccess: true };
	} catch (error) {
		return { isSuccess: false, error: error.message };
	}
};

export const forgotPasswordConfirm = async (email, otp, password) => {
	try {
		await apiRequest('/auth/forgot-password/confirm', 'POST', { email, otp, password }, false);

		return { isSuccess: true };
	} catch (error) {
		return { isSuccess: false, error: error.message };
	}
};

export const logout = () => {
	Intercom('shutdown');

	localStorage.clear();

	window.open('/', '_self');
};
