import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import Loader from '../utils/loader';

import { swapCurrencyAPI } from '../../services/swap';

import { walletCurrencies } from '../../utils/constants/currencies';
import { SwapSchema } from '../../utils/validations/user-schema';

import { Header } from '../../components/navigation/header';
import { Input } from '../../components/inputs/text-input';
import { PrimaryButton } from '../../components/buttons/primary-button';
import { useSelector } from 'react-redux';
import SelectInput from '../../components/inputs/select-input';
import useValidation from '../../hooks/useValidation';
import numeral from 'numeral';

export default function SwapCurrency() {
	const navigate = useNavigate();

	const { userWallets } = useSelector((state) => state.accounts);

	const [fromCurrency, setFromCurrency] = useState('');
	const [toCurrency, setToCurrency] = useState('');
	const [convertedAmount, setConvertedAmount] = useState();
	const [swapConfirm, setSwapConfirm] = useState(false);
	const [errorMsg, setErrorMsg] = useState();
	const [amount, setAmount] = useState(100);
	const [isLoading, setIsLoading] = useState(false);
	const [countdown, setCountdown] = useState(60); // Initial countdown value
	const [intervalId, setIntervalId] = useState(null);

	const { validationErrMsg, validate } = useValidation(SwapSchema);

	const next = async () => {
		const isValid = validate({
			fromCurrency,
			toCurrency,
			amount: parseInt(amount),
		});

		if (!isValid) return;

		const response = await getRates();
		if (!response) return;

		setSwapConfirm(true);
	};

	const getRates = async () => {
		setIsLoading(true);
		setErrorMsg('');
		const response = await swapCurrencyAPI({ fromCurrency, toCurrency, amount });
		if (!response.isSuccess) {
			setErrorMsg(response.error ?? 'Something went wrong please try again later!');
			setIsLoading(false);
			return false;
		}
		setConvertedAmount(response.data);
		setIsLoading(false);
		return true;
	};

	const swap = async () => {
		if (!isLoading) {
			setIsLoading(true);

			const response = await swapCurrencyAPI({
				fromCurrency,
				toCurrency,
				amount: parseFloat(amount),
				confirm: true,
			});

			if (!response.isSuccess) {
				setErrorMsg(response.error ?? 'Something went wrong please try again later or contact support!');
				setIsLoading(false);
				return;
			}
			setIsLoading(false);
			navigate('/');
		}
	};

	useEffect(() => {
		if (swapConfirm) {
			let secondsLeft = countdown;

			const id = setInterval(() => {
				secondsLeft--;
				setCountdown(secondsLeft);

				if (secondsLeft <= 0) {
					setCountdown(60);
					getRates();
					secondsLeft = 60; // Reset the countdown value after getting rates
				}
			}, 1000);

			setIntervalId(id);

			// Call getRates initially when swapConfirm becomes true

			getRates();
		} else {
			clearInterval(intervalId);
			setIntervalId(null);
			setCountdown(60); // Reset countdown when swapConfirm is false
		}

		// Cleanup interval on component unmount or when swapConfirm changes
		return () => {
			if (intervalId) {
				clearInterval(intervalId);
			}
		};
	}, [swapConfirm]);

	return isLoading ? (
		<Loader />
	) : (
		<div>
			<Header
				backbuttonNavigation={() => {
					if (!swapConfirm) return navigate(-1);
					else return setSwapConfirm(false);
				}}
			/>
			<div className="mb-20">
				<div className="flex justify-center">
					<h1 className="font-bolder text-3xl mb-2 text-center">Swap Currency</h1>
				</div>

				<div className="flex items-center justify-center xsm:flex-col ">
					{userWallets.map((account) => (
						<div>
							<h1 className="text-xl text-center">Paytora {account.symbol} Balance</h1>
							<div className="p-5 border border-black rounded m-2" key={account.sdkFinanceId}>
								<div className="flex items-center">
									<div className="me-3 w-10 h-10 rounded-full bg-gray-500 flex items-center justify-center text-white">{account.symbol}</div>
									<div>{account.currency + ' - ' + numeral(account.balance).format('0,0.00')}</div>
								</div>
							</div>
						</div>
					))}
				</div>

				<div className="flex flex-col items-center justify-center p-5">
					{!swapConfirm && (
						<div className="">
							<p className="font-bold mb-1 text-center text-2xl">From Currency</p>

							<SelectInput
								onChange={setFromCurrency}
								options={userWallets.map((wallet) => ({
									value: wallet.currency,
									label: wallet.currency,
								}))}
								defaultValue={{ label: 'Select from currency' }}
							/>

							<div className="mt-5">
								<p className="font-bold mb-1 text-center text-2xl">Amount To Swap</p>
								<Input
									placeholder="Amount To Swap"
									type="number"
									value={amount}
									onChange={(value) => setAmount(value)}
									className="bg-secondary border-2 border-black inline-block me-10"
									inputMode="numeric"
									pattern="[0-9]"
								/>
							</div>
							<p className="font-bold mb-1 text-center text-2xl">To Currency</p>

							<SelectInput
								onChange={setToCurrency}
								options={walletCurrencies.map((ext) => ({
									value: ext,
									label: ext,
								}))}
								defaultValue={{ label: 'Select to currency' }}
							/>
							<div className=" flex flex-col items-center justify-center p-5">
								<PrimaryButton onClick={next} text="NEXT" className="p-10 mb-5" />
								<p className="font-bold text-lg mb-2">*Minimum 100 in dollar value</p>
							</div>
						</div>
					)}
					<p className="font-bold text-red-500">{errorMsg}</p>
					{validationErrMsg && <p className="font-bold text-red-500">{validationErrMsg}</p>}

					{swapConfirm && (
						<div>
							<div className="flex flex-col items-center justify-center w-full">
								<p className="mb-5">
									You swap {amount} {fromCurrency} to {toCurrency}
								</p>

								<p>You will receive the following amount: </p>
								<Input
									disabled={true}
									placeholder="Amount After Swap"
									type="text"
									value={convertedAmount}
									className="bg-secondary border-2 border-black inline-block"
								/>
							</div>
							<p>Rates will updated in: {countdown} seconds hurry up to exchange!</p>
							<div className="flex justify-center p-5">
								<PrimaryButton text="Confirm SWAP" className="p-5" onClick={swap} />
							</div>
						</div>
					)}
				</div>
			</div>
		</div>
	);
}
