import React, { useState } from 'react';
import Modal from 'react-modal';

import { PrimaryButton } from '../buttons/primary-button';
import { SecondaryButton } from '../buttons/secondary-button';

import Loader from '../../pages/utils/loader';

import { verifyUserPincode } from '../../services/pincode';
import { freezeVirtualCardAPI, unFreezeVirtualCard, terminateVirtualCard, showPhysicalCardPin } from '../../services/cards.js';

import { PincodeModal } from './pincodeModal.jsx';
import TopUpModal from './topupModal.jsx';

export const CardActionsModal = ({ isOpen, onClose, selectedCard }) => {
	const [isTopUpSelected, setTopupSelected] = useState(false);

	const [isLoading, setIsLoading] = useState(false);

	const [modalTitle, setModalTitle] = useState('Please select an action');

	const [errMsg, setErrMsg] = useState('');

	const [showPincodeModal, setShowPincodeModal] = useState(false);

	const [cardPin, setCardPin] = useState(null);

	const customStyles = {
		content: {
			top: '50%',
			left: '50%',
			right: 'auto',
			bottom: 'auto',
			marginRight: '-50%',
			transform: 'translate(-50%, -50%)',
			width: '400px', // Adjust the width as needed
		},
	};

	const pincodeModalHandler = async (pincode) => {
		if (pincode.length !== 6) {
			setErrMsg('Invalid pincode');
			return;
		}

		const response = await verifyUserPincode(pincode);
		if (response.isSuccess) {
			setShowPincodeModal(false);
			await terminateCard();
			return;
		}
		setErrMsg('Pincode isnt valid please try again.');
		return;
	};

	const freezeCard = async () => {
		if (!isLoading) {
			setIsLoading(true);

			const response = await freezeVirtualCardAPI(selectedCard._id);
			if (response.isSuccess) {
				onClose();
				window.location.reload();
			} else {
				setErrMsg(response.error);
			}
		}
		setIsLoading(false);
	};

	const unfreezeCard = async () => {
		if (!isLoading) {
			setIsLoading(true);

			const response = await unFreezeVirtualCard(selectedCard._id);
			if (response.isSuccess) {
				onClose();
				window.location.reload();
			} else {
				setErrMsg(response.error);
			}
		}
		setIsLoading(false);
	};

	const terminateCard = async () => {
		if (showPincodeModal) {
			if (!isLoading) {
				setIsLoading(true);

				const response = await terminateVirtualCard(selectedCard._id);
				if (response.isSuccess) {
					onClose();
					window.location.reload();
				} else {
					setErrMsg(response.error);
				}
			}
			setIsLoading(false);
		} else {
			setShowPincodeModal(true);
		}
	};

	const showCardPin = async () => {
		if (!isLoading) {
			setIsLoading(true);

			const response = await showPhysicalCardPin(selectedCard._id);
			if (response.isSuccess) {
				setModalTitle('Your Card PIN');
				setCardPin(response.data);
				setTimeout(() => {
					setCardPin(null);
				}, 10000);
			} else {
				setErrMsg(response.error);
			}
		}
		setIsLoading(false);
	};

	return showPincodeModal ? (
		<PincodeModal
			isOpen={showPincodeModal}
			onClose={() => {
				setShowPincodeModal(false);
				setErrMsg('');
			}}
			subTitle={'The card will not be available anymore your balance will move to your new applied card!'}
			onAction={async (pincode) => await pincodeModalHandler(pincode)}
			buttonText={'Verify & Terminate Your Card'}
			errMessage={errMsg}
		/>
	) : (
		<Modal
			isOpen={isOpen}
			onRequestClose={onClose}
			contentLabel={modalTitle}
			ariaHideApp={false} // This is to prevent a warning in the console
			style={customStyles}
		>
			{!isLoading && <h2 className="text-2xl text-center">{modalTitle}</h2>}

			{isLoading ? (
				<Loader className="h-[10rem]" />
			) : !isTopUpSelected && !cardPin ? (
				<div className="flex flex-col">
					{selectedCard.status === 'A' && (
						<PrimaryButton
							onClick={() => {
								setTopupSelected(true);
								setModalTitle('');
							}}
							text="Card Deposit"
							className="p-5 mb-2 mt-2"
						/>
					)}
					{selectedCard.status === 'A' && <PrimaryButton onClick={freezeCard} text="Freeze Card" className="p-5 mb-2" />}
					{selectedCard.status === 'F' && <PrimaryButton onClick={unfreezeCard} text="Unfreeze Card" className="p-5 mb-2" />}
					<PrimaryButton onClick={terminateCard} text="Terminate Card" className="p-5 mb-2" />
					{selectedCard.cardTemplate.type === 'physical' && (
						<PrimaryButton onClick={async () => await showCardPin()} text="Show Pin" className="p-5 mb-2" />
					)}
					<SecondaryButton onClick={onClose} text="Close" className="p-5 mb-2" />

					<p className="text-red-500">{errMsg}</p>
				</div>
			) : !cardPin ? (
				<TopUpModal
					closeHandler={() => {
						setTopupSelected(false);
						setModalTitle('Please select an action');
					}}
					cardId={selectedCard}
				/>
			) : (
				<div className="text-xl text-center p-5">
					<p>{cardPin}</p>
					<PrimaryButton
						text="Close"
						className="p-5 mt-5"
						onClick={() => {
							setModalTitle('Please select an action');
							setCardPin(null);
						}}
					/>
				</div>
			)}
		</Modal>
	);
};
