import React, { useState } from 'react';

import Modal from 'react-modal';

import { PrimaryButton } from '../buttons/primary-button';
import Loader from '../../pages/utils/loader';

export const VirtualCardApplyModal = ({ isOpen, onClose, onCardApplySubmit, cardPrice }) => {
	const [errMsg, setErrMsg] = useState('');
	const [isLoading, setIsLoading] = useState(false);

	const customStyles = {
		content: {
			top: '50%',
			left: '50%',
			right: 'auto',
			bottom: 'auto',
			marginRight: '-50%',
			transform: 'translate(-50%, -50%)',
			width: '400px', // Adjust the width as needed
		},
	};

	// Modal.setAppElement('#root');

	const [formData, setFormData] = useState({
		agreeToTerms: false,
		agreeToPrivacyPolicy: false,
	});

	const handleCheckboxChange = (fieldName) => {
		setFormData((data) => ({ ...data, [fieldName]: !data[fieldName] }));
	};

	return (
		<Modal
			isOpen={isOpen}
			onRequestClose={onClose}
			contentLabel={`Apply for a new virtual card`}
			ariaHideApp={false} // This is to prevent a warning in the console
			style={customStyles}
			shouldCloseOnOverlayClick={false}
		>
			{isLoading ? (
				<Loader className={'h-[10rem]'} />
			) : (
				<div>
					<h2 className="text-2xl text-center">{`Apply for a new virtual card`}</h2>
					<p className="text-center text-red-500">You will be charged for {cardPrice}</p>
					<div>
						<div className="flex items-center mt-3">
							<input type="checkbox" id="termsCheckbox" checked={formData.agreeToTerms} onChange={() => handleCheckboxChange('agreeToTerms')} />
							<label htmlFor="termsCheckbox" className="ml-2">
								I agree to the
								<span className="text-primary cursor-pointer m-2" onClick={() => window.open('/card/terms', '_blank')}>
									Terms and Conditions
								</span>
							</label>
						</div>

						<div className="flex items-center mt-3 mb-3">
							<input
								type="checkbox"
								id="privacyCheckbox"
								checked={formData.agreeToPrivacyPolicy}
								onChange={() => handleCheckboxChange('agreeToPrivacyPolicy')}
							/>
							<label htmlFor="privacyCheckbox" className="ml-2">
								I agree to the
								<span className="text-primary cursor-pointer m-2" onClick={() => window.open('/card/privacy', '_blank')}>
									Privacy Policy
								</span>
							</label>
						</div>

						<p className="text-red-500">{errMsg}</p>

						<div className="flex justify-center mt-10">
							<PrimaryButton
								text={'Apply'}
								onClick={async () => {
									if (!isLoading) {
										setIsLoading(true);

										const response = await onCardApplySubmit(formData);

										if (response && response.error) setErrMsg(response.error);
										setIsLoading(false);
									}
								}}
								className="p-5 me-2"
							/>
							<PrimaryButton onClick={onClose} text="Close" className="p-5" />
						</div>
					</div>
				</div>
			)}
		</Modal>
	);
};
