import { useEffect, useState } from 'react';

import { Header } from '../../../components/navigation/header';
import { Input } from '../../../components/inputs/text-input';
import { PrimaryButton } from '../../../components/buttons/primary-button';

import Loader from '../../utils/loader';
import { wireTopUpCurrencies } from '../../../utils/constants/currencies';

import { otherTopUpAccountAPI } from '../../../services/transactions';
import { useNavigate, useParams } from 'react-router-dom';

export default function TopUpOtherSelectAmount() {
	const navigate = useNavigate();

	const { currencyCode } = useParams();

	const [currency, setCurrency] = useState(wireTopUpCurrencies[0]);
	const [amount, setAmount] = useState();

	const [errMsg, setErrMsg] = useState('');

	const [isLoading, setIsLoading] = useState(false);

	useEffect(() => {
		if (currencyCode && !wireTopUpCurrencies.includes(currencyCode)) {
			navigate('/404', { replace: true });
		}
	}, [currencyCode]);

	const topUp = async () => {
		if (!amount) return setErrMsg('Please enter a valid amount');

		if (!isLoading) {
			setIsLoading(true);
			const selectedCurrency = currencyCode ?? currency;

			const response = await otherTopUpAccountAPI({ amount, currency: selectedCurrency });
			if (!response.isSuccess) {
				setErrMsg(response.error ?? 'Something went wrong please try again later.');
			} else {
				setErrMsg('');
				window.open(response.data, '_self');
			}
			setIsLoading(false);
		}
	};

	if (isLoading) {
		return <Loader />;
	}

	return (
		<div>
			<Header />
			<div>
				<div className=" flex justify-center p-5 flex-col items-center">
					<h1 className="font-bolder text-2xl mb-10 text-center">Please choose currency & amount to deposit</h1>
					{currencyCode ? (
						<p className="font-bolder mb-5 text-xl">Deposit {currencyCode} to your wallet</p>
					) : (
						<select
							value={currency}
							onChange={(e) => {
								setCurrency(e.target.value);
							}}
							className={
								'bg-secondary rounded-xl py-2 px-4  max-w-md text-black placeholder:text-black  text-center mb-5 border-2 border-black inline-block w-full'
							}
						>
							{wireTopUpCurrencies.map((currency) => (
								<option key={currency} value={currency}>
									{currency}
								</option>
							))}
						</select>
					)}

					<Input
						placeholder="Amount To Deposit"
						type="number"
						value={amount}
						onChange={(value) => setAmount(value)}
						className="text-white placeholder:text-white"
						inputMode="numeric"
						pattern="[0-9]"
					/>
				</div>
			</div>
			<div className="flex flex-col items-center">
				<PrimaryButton text="Deposit to Your Paytora Account" onClick={topUp} className="p-10" />
				<p className="text-red-500 font-bold text-lg text-center mb-5">{errMsg}</p>
			</div>
		</div>
	);
}
