export const convertDateToString = (date) => {
  const dateObject = new Date(date);

  // Format the date using toLocaleString() with options for Month, Day, and Year
  const formattedDate = dateObject.toLocaleString('en-US', {
    month: 'long', // Full month name (e.g., "August")
    day: 'numeric', // Day of the month (e.g., "17")
    year: 'numeric', // Full year (e.g., "2023")
  });

  return formattedDate;
};
