import ReactSelect from 'react-select';

export default function SelectInput({ options, onChange, placeHolder, defaultValue, className, name }) {
	const customStyles = {
		control: (provided) => ({
			...provided,
			backgroundColor: '#ebcbff', // Background color
			borderRadius: '0.5rem', // Border radius
			border: '2px solid black', // Border color
		}),
		// Customize other elements as needed
		indicatorSeparator: () => ({
			display: 'none', // Hide the indicator separator
		}),
		dropdownIndicator: (provided) => ({
			...provided,
			color: 'black', // Dropdown arrow color
		}),
		input: (provided) => ({
			...provided,
			color: 'black', // Input text color
		}),
		placeholder: (provided) => ({
			...provided,
			color: 'black',
		}),
		// Add more custom styles as needed
	};

	return (
		<ReactSelect
			name={name}
			id={name}
			onChange={(e) => onChange(e.value)}
			placeholder={placeHolder}
			options={options}
			defaultValue={defaultValue}
			styles={customStyles}
			className={`text-center mb-5 ${className}`}
		/>
	);
}
