import React from 'react';
import numeral from 'numeral';
import { PrimaryButton } from '../buttons/primary-button';

export const CreditCardTemplateItem = ({ card, cardApplyModalHandler }) => {
	const getCardTypeByLimit = () => {
		if (card.limit === 250000) return { name: 'Infinity', color: 'text-black' };

		if (card.limit === 100000) return { name: 'Gold', color: 'text-orange' };

		return { name: 'Premium', color: 'text-primary' };
	};

	return (
		<div className="p-2 text-gold font-bolder">
			<div className="text-center text-primary font-bold text-lg">
				{card.type.charAt(0).toUpperCase() + card.type.slice(1)} Card <br />
				<h2 className={`${getCardTypeByLimit().color}`}> Card Type: {getCardTypeByLimit().name} </h2>
				Card Limit: {numeral(card.limit).format('0,0') + card.currency.symbol}
			</div>
			<div
				style={{
					backgroundColor: 'black',
					border: '2px solid gold',
					padding: '20px',
					borderRadius: '10px',
				}}
			>
				<div className="flex justify-between">
					<div className="flex-col flex items-center justify-center">
						<div className="flex">
							<img src="/assets/GooglePay.png" alt="Google Pay" width={50} className="me-5" />
							<img src="/assets/ApplePay.png" alt="Apple Pay" width={50} className="me-5" />
						</div>
						<div className="flex mt-5">
							<img src="/assets/nfc-chip.png" alt="NFC" width={30} className="me-5" />
							<img src="/assets/nfc-gold.png" alt="NFC" className="h-5" />
						</div>
					</div>
					<img src="/assets/card-logo.png" alt="Logo" width={150} />
				</div>

				<div className="flex mt-10">
					<p>**** **** **** ****</p>
				</div>
				<div className="flex justify-between items-center">
					<p>EXP **/**</p>
					<img src="/assets/mastercard-transperant.png" alt="MasterCard" width={50} />
				</div>
			</div>
			<PrimaryButton text="Apply" className="w-full mt-2" onClick={cardApplyModalHandler} />
		</div>
	);
};
