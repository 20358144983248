import React from 'react';

import { Header } from '../../components/navigation/header';

const TermsAndConditions = () => {
	return (
		<div>
			<Header showHome={false} showLogo={false} showBack={false} showBalance={false} showUser={false} />
			<div className="text-left ms-20 mb-10 w-[80%]">
				<h1 className="font-bolder text-3xl mb-10">
					Terms & <span className="text-primary">Conditions</span>
				</h1>
				<p className="font-bolder mb-5 text-red-500">
					PAYTORA PAYMENT LTD / Canada Reg. N BC1411589 / www.qr-ew.com a Paytora Payment LTD product. FINTRAC registered MSB23894006 / mail to:
					management@pay-tora.com 1052-1771 ROBSON STREET , VANCOUVER, BC, CANADA V6G3B7
				</p>
				<p className="mb-5">
					*Thank you for choosing <b> "Paytora payment ltd (digital bank by paytora)"</b> your personal digital wallet -and the best way for managing
					your money. Please note that by accessing and/or using the Application and the Services, you are acknowledging that you have read and
					understood these Terms (including our Privacy Policy).
				</p>

				<p className="mb-5">
					1. Summary of Services
					<br />
					<b>" Paytora payment ltd (digital bank by paytora)"</b> offers the following Services via the Application and/or as part of collaborations
					with its business entities:
					<br />
					(1a) deposit funds into your <b>" Paytora payment ltd (digital bank by paytora)" </b> account: <br />
					<br />
					(1b)Remittance transactions to bank accounts All over the world according to the regulations and conditions of the receiving account.
					<br />
					(1c) Currency exchange.
					<br />
					(1d) Issuance of prepaid cards linked to the customer’s account through third parties.
					<br />
					(1e) Money transfers between the app users (P2P). The following key points of the Terms of Use are only brought for your convenience, They
					do not substitute the full Terms.
				</p>

				<p className="font-bolder mb-5 text-center">GENERAL TERMS AND CONDITIONS</p>

				<p className="font-bolder mb-5">
					<u>Opening an account</u>
				</p>

				<p className="mb-5">
					To open an account with <b>" Paytora payment ltd (digital bank by paytora)"</b>, or apply for a service provided by
					<b>" Paytora payment ltd (digital bank by paytora)"</b> you must complete the appropriate procedures including completing any applicable
					account opening and operating authority.
					<b>" Paytora payment ltd (digital bank by paytora)</b> reserves the right not to open an account or provide a service for any reason
					whatsoever. You may also be asked at any time to provide information about people with access to your accounts, (such as authorized
					signatories), This may include satisfactory proof of their identity and/or authority to act on your behalf. If you are an organization you
					may also be asked to provide satisfactory proof of identity of your beneficial owners (e.g. certain shareholders, if you are a company).
				</p>

				<p className="font-bolder mb-5">
					<u>Accuracy of information</u>
				</p>
				<p className="mb-5">
					<b>" Paytora payment ltd (digital bank by paytora)"</b> does not accept any responsibility or liability for the accuracy of the information
					given by you, or anyone acting on your behalf (other than us), in any instruction. You will be solely responsible for ensuring such
					information, including other parties’ bank account numbers and/or clearing codes, is accurate.
				</p>

				<p className="font-bolder mb-5">
					<u>Inward Payment Processing</u>
				</p>
				<p className="mb-5">
					Each account has an beneficial name and account number to be used to identify the correct customer and the specific account that the payment
					is to be credited to. <b>" Paytora payment ltd (digital bank by paytora)</b> is entitled to rely on any valid beneficial name and account
					number included on any inward payment as being correct, notwithstanding any other reference or instruction information that may indicate
					which customer is entitled to the inward payment. If the payment is in a different currency from the currency of the account the payment is
					being credited to, you authorize <b>" Paytora payment ltd (digital bank by paytora)"</b> to exchange the payment for an amount in the
					currency of the account. If you wish to claim a payment that was credited to a different customer’s account because the payment contained
					wrong beneficiary’s information of the other customer’s account, <b>"Paytora payment ltd (digital bank by paytora)" </b>is not liable for
					any loss you suffer as a result of and delay or inability to recover or to fully recover the payment and you indemnify
					<b> "Paytora payment ltd (digital bank by paytora)" </b>for any costs incurred in seeking to recover the payment.
				</p>

				<p className="font-bolder mb-5">
					<u>Return the funds to the originator</u>
				</p>
				<p className="mb-5">
					Inward payment transactions may be reversed out of an account by <b>"Paytora payment ltd (digital bank by paytora)"</b> if: - The payment
					was made by mistake and <b>"Paytora payment ltd (digital bank by paytora)"</b> is liable to repay the originator. - The payment was credited
					to the account because of an error made by <b>" Paytora payment ltd (digital bank by paytora)"</b> - The payment was an amount that the
					customer was not entitled to, and the amount was procured by fraud, theft or breach of trust, and believes it is liable to return the
					payment to the rightful owner. - <b>" Paytora payment ltd (digital bank by paytora)"</b> is liable to pay the amount on account of any
					seizure or court order or similar.
				</p>

				<p className="font-bolder mb-5">
					<u>Clearance</u>
				</p>
				<p className="mb-5">
					The time it takes for funds to become available will depend on a number of factors, including the type of payment used and/or whether the
					payment was made on a non-business day or outside banking hours. The proceeds of cheques and other deposits may not be available until
					<b>" Paytora payment ltd (digital bank by paytora)"</b> is reasonably satisfied that the amounts deposited will clear.
				</p>

				<p className="font-bolder mb-5">
					<u>Suspending accounts</u>
				</p>
				<p className="mb-5">
					<b>" Paytora payment ltd (digital bank by paytora)"</b> may immediately suspend the operation of your account, either generally or in
					relation to a specific matter or matters, for various reasons which may include: to comply with a court order or otherwise comply with any
					law or regulation; - if <b>" Paytora payment ltd (digital bank by paytora)"</b> has been notified by any party of a dispute over the
					ownership of funds or the operation of an account; or is using or obtaining, or may use or obtain, a service or money illegally or
					fraudulently; where there is not enough money to cover cheques, payment instructions or other obligations (including instructions which will
					or may arise later and <b>" Paytora payment ltd (digital bank by paytora)"</b> charges. Upon suspending an account or may use or obtain, a
					service or money illegally or fraudulently; where there is not enough money to cover cheques, payment instructions or other obligations
					(including instructions which will or may arise later and <b>"Paytora payment ltd (digital bank by paytora)"</b> charges). Upon suspending
					an account <b>"Paytora payment ltd (digital bank by paytora)"</b> may lift the suspension, pay funds in your account to you or to the bank
					or person which deposited funds to your account, or seek directions from the Court in relation to the funds in your account.
				</p>

				<p className="font-bolder mb-5">
					<u>Closing accounts and withdrawing products and services</u>
				</p>
				<p className="mb-5">
					You may close an account by giving notice to <b>"Paytora payment ltd (digital bank by paytora)"</b> in writing.
					<b>"Paytora payment ltd (digital bank by paytora)"</b> may close your account or withdraw a product or service if
					<b>" Paytora payment ltd (digital bank by paytora)"</b> believes it has reasonable may lift the suspension, pay funds in your account to you
					or to the bank or person which deposited funds to your account, or seek directions from the Court in relation to the funds in your account.
					Closing accounts and withdrawing products and services You may close an account by giving notice to
					<b>" Paytora payment ltd (digital bank by paytora)"</b> in writing. <b>" Paytora payment ltd (digital bank by paytora)"</b>
					may close your account or withdraw a product or service if <b>"Paytora payment ltd (digital bank by paytora)"</b> believes it has reasonable
					grounds for doing so provided you will be given at least 15 days’ notice. <b>" Paytora payment ltd (digital bank by paytora)"</b> may close
					your account or withdraw a product or service immediately and without prior notice if:
					<b>" Paytora payment ltd (digital bank by paytora)"</b> learns of your or any guarantor‘s death, lack of legal capacity, or that you or any
					guarantor has suffered a Bankruptcy Event or an Insolvency Event; any third party claims an interest in any of your accounts; - there is not
					enough money to cover cheques, payment instructions or other obligations (including obligations which will or may arise later and
					<b>"Paytora payment ltd (digital bank by paytora)"</b> charges); - <b>" Paytora payment ltd (digital bank by paytora)"</b> is required to by
					a court order or any law or regulation; <b>" Paytora payment ltd (digital bank by paytora)"</b> determines that you are a “politically
					exposed person” (as defined in the Anti-Money Laundering and Countering Financing of Terrorism Act 2009); you have acted unlawfully; - you
					have breached these General Terms and Conditions or any other applicable terms and conditions; or you have acted abusively to
					<b> "Paytora payment ltd (digital bank by paytora)"</b>’ staff. You are required to reimburse
					<b>"Paytora payment ltd (digital bank by paytora)"</b> for any expenses <b>"Paytora payment ltd (digital bank by paytora)"</b> may incur in
					closing or suspending any of your accounts.
				</p>

				<p className="font-bolder mb-5 text-red-500">
					PAYTORA PAYMENT LTD / Canada Reg. N BC1411589 / www.qr-ew.com a Paytora Payment LTD product. FINTRAC registered MSB23894006 / mail to:
					management@pay-tora.com 1052-1771 ROBSON STREET, VANCOUVER, BC, CANADA V6G3B7
				</p>

				<p className="font-bolder mb-5">
					<u>Interest rates & rates</u>
				</p>
				<p className="mb-5">
					Interest rates & retes on amounts owed by or to
					<b>"Paytora payment ltd (digital bank by paytora)"</b> are subject to market fluctuations and may be varied by
					<b>"Paytora payment ltd (digital bank by paytora)"</b> at any time. This is subject to any other agreement you make with "Paytora payment
					ltd (digital bank by paytora)".
				</p>

				<p className="font-bolder mb-5">
					<u>Fees and charges</u>
				</p>
				<p className="mb-5">
					You are responsible for, and <b>" Paytora payment ltd (digital bank by paytora)"</b> may deduct from your accounts, bank charges, government
					charges, and any amounts owed to <b>"Paytora payment ltd (digital bank by paytora)"</b>, including: transaction and service fees Fees: With
					the exception of monthly account management fees of 9.90 euros, all fees are transparent to you in every banking operation you perform
					within the platform. - <b>"Paytora payment ltd (digital bank by paytora)"</b> exposure to theft or fraud. These restrictions may include
					maximum or minimum individual transaction limits, maximum or minimum daily transaction limits and cut off times for lodging transactions for
					payment so they are processed on a specified due date. Details of these transaction limits and cut off times are available upon request.
				</p>

				<p className="font-bolder mb-5">
					<u>Mistaken payments</u>
				</p>
				<p className="mb-5">
					If <b>" Paytora payment ltd (digital bank by paytora)"</b> is satisfied that funds have been credited to your account by mistake,
					<b>"Paytora payment ltd (digital bank by paytora)"</b> may, in its absolute discretion, reverse such credit without notice to you and you
					will be liable to repay any such an amount. You agree to provide all information to <b>"Paytora payment ltd (digital bank by paytora)"</b>{' '}
					which
					<b>"Paytora payment ltd (digital bank by paytora)"</b> requires in order to manage its anti-money-laundering and countering
					terrorism-financing obligations, to manage its economic trade sanctions risks, or to comply with any laws, rules or regulations in Canada or
					any other country.
				</p>

				<p className="font-bolder mb-5">
					<u>Financial institution customers agree to the following additional terms concerning AML/CFT obligations:</u>
				</p>
				<p className="mb-5">
					1. The customer agrees to comply with AML/CFT laws applicable to it, and/or as adopted in order to be accepted by
					<b>"Paytora payment ltd (digital bank by paytora)" </b>as having adequate and effective AML/CFT controls even where it may not be subject to
					adequate AML/CFT laws. 2. The customer agrees to provide originator data including full name, account number, customer number or other data
					as agreed, with the payment submission, or, if that is not practical, on request.
				</p>

				<p className="font-bolder mb-5">
					<u>Privacy</u>
				</p>
				<p className="mb-5">
					<b>" Paytora payment ltd (digital bank by paytora)"</b> adopts strict rules of confidentiality about its current and former customers’
					affairs, and will observe all relevant privacy laws. <b>" Paytora payment ltd (digital bank by paytora)"</b> will do its very best to ensure
					your personal information is accurate. <b>" Paytora payment ltd (digital bank by paytora)"</b> asks that you promptly inform it of any
					changes in your personal details (including your address, telephone, mobile or facsimile numbers and email address). You may ask to access
					any personal information that <b>" Paytora payment ltd (digital bank by paytora)"</b> holds about you, and request correction of your
					personal information, in accordance with your rights under the Privacy Act 1993. A fee may be charged.Any information you provide to{' '}
					<b>"Paytora payment ltd (digital bank by paytora)"</b> in the course of our banker/customer relationship, is collected for the purpose of
					our banker/customer relationship and may be used to further the relationship between you and{' '}
					<b>" Paytora payment ltd (digital bank by paytora)"</b>. This includes communicating with you using any contact details you have provided to{' '}
					<b>"Paytora payment ltd (digital bank by paytora)"</b>, including, but not limited to, post, telephone, txt, facsimile and email.
				</p>

				<p className="font-bolder mb-5">
					<u>REFUND UPON INACTIVITY OF THE ACCOUNT</u>
				</p>
				<p className="mb-5">
					If you do not use your account for more than 12 months, then it becomes inactive and your account funds will be refunded in compliance with
					all refund's rules associated with the termination of use of the website.
				</p>

				<p className="font-bolder mb-5 text-red-500">
					PAYTORA PAYMENT LTD / Canada Reg. N BC1411589 / www.qr-ew.com a Paytora Payment LTD product. FINTRAC registered MSB23894006 / mail to:
					management@pay-tora.com 1052-1771 ROBSON STREET, VANCOUVER, BC, CANADA V6G3B7.
				</p>
				<p>Thank you for choosing Paytora Payment LTD as your financial solution 😊 </p>
			</div>
		</div>
	);
};

export default TermsAndConditions;
