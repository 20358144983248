import { useEffect, useState } from 'react';
import { PrimaryButton } from '../buttons/primary-button';
import { SecondaryButton } from '../buttons/secondary-button';
import { Input } from '../inputs/text-input';
import { useSelector } from 'react-redux';
import { getFeeByType } from '../../services/fees';
import Loader from '../../pages/utils/loader';
import { topUpUserCardAPI } from '../../services/cards';
import { CustomModal } from '../utils/modal';

export default function TopUpModal({ closeHandler, cardId }) {
	const { userWallets } = useSelector((state) => state.accounts);

	const [amount, setAmount] = useState();

	const [errMsg, setErrMsg] = useState('');

	const [totalFee, setTotalFee] = useState(0);

	const [isLoading, setIsLoading] = useState(false);

	const [isTopUpSuccessModalOpen, setIsTopUpSuccessModalOpen] = useState(false);

	const topUpHandler = async () => {
		if (!amount || amount < 0) {
			setErrMsg('Please select a valid amount');
			return;
		}

		const data = {
			currency: 'EUR',
			amount,
			cardId,
		};
		if (!isLoading) {
			setIsLoading(true);
			const response = await topUpUserCardAPI(data);
			if (response.error) setErrMsg(response.error);
			else {
				setIsTopUpSuccessModalOpen(true);
			}
			setIsLoading(false);
		}
	};

	useEffect(() => {
		const fetchData = async () => {
			const feesResponse = await getFeeByType({ type: 'card_top_up' });
			if (feesResponse.isSuccess) setTotalFee(feesResponse.data.cost);
		};

		fetchData();
	}, []);

	return isLoading ? (
		<Loader />
	) : (
		<div>
			<div className="flex flex-col items-center p-5">
				<div className="mt-5">
					<p className="text-center">
						<b>Your available amount is:</b> {userWallets.find((wallet) => wallet.currency === 'EUR').balance + '€' || '0.00'}
					</p>
					<div className="flex">
						<Input
							placeholder="Amount To Deposit"
							type="number"
							value={amount}
							onChange={(value) => setAmount(value)}
							className="bg-secondary border-2 border-black inline-block me-2"
							inputMode="numeric"
							pattern="[0-9]"
						/>
						<p className="text-xl font-bold">€</p>
					</div>
				</div>
				<div className="text-center mb-4 font-bolder">
					{totalFee > 0 && amount > 0 && (
						<p className="mb-1 mt-1 text-red-500">
							{parseInt(amount) - totalFee + '€'} <br /> will be deposit to your virtual card
						</p>
					)}
				</div>
				<div className="flex flex-col items-center justify-center">
					<PrimaryButton text="Deposit" className="p-5 mb-5" onClick={() => topUpHandler()} />
					<SecondaryButton
						onClick={() => {
							closeHandler();
						}}
						text="Close"
						className="p-5 mb-2"
					/>
				</div>
				<p className="text-red-500 text-center">{errMsg}</p>
			</div>
			<CustomModal
				isOpen={isTopUpSuccessModalOpen}
				onClose={() => setIsTopUpSuccessModalOpen(false)}
				title={'You successfully deposited to your card!'}
				children={
					<div className="flex justify-center">
						<PrimaryButton
							onClick={() => {
								setIsTopUpSuccessModalOpen(false);
								window.location.reload();
							}}
							text="Close"
							className="p-5 mt-5"
						/>
					</div>
				}
			/>
		</div>
	);
}
