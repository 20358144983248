import { Header } from '../../components/navigation/header';

export default function SuspendedPage() {
	return (
		<div className="bg-red-100 h-screen">
			<Header showLogo={false} showBack={false} showBalance={false} background="bg-red-100" />
			<div className="flex  items-center flex-col">
				<img alt="404" src="/assets/logo.png" className="w-[50%] mt-36"></img>
				<h1 className="xsm:text-2xl text-3xl text-center p-10 text-red-500">
					Your Account Has Been Suspended And Will Be Check By Our Finance Authorthy, You can contact us at support@qrewallet.com
				</h1>
			</div>
		</div>
	);
}
