import { useEffect, useState } from 'react';

import Loader from '../utils/loader';

import { setUserPincode, verifyUserPincode } from '../../services/pincode.js';
import { cardApplyAPI, getUserCardsAPI } from '../../services/cards';

import { Header } from '../../components/navigation/header';
import { CreditCardTemplateItem } from '../../components/credit-card/template-card-item';
import { CreditCardItem } from '../../components/credit-card/credit-card-item';
import { VirtualCardApplyModal } from '../../components/modals/virtualCardApplyModal.jsx';
import { CustomModal } from '../../components/utils/modal';
import { PrimaryButton } from '../../components/buttons/primary-button';
import { CardActionsModal } from '../../components/modals/cardActionsModal';
import { PhysicalCardApplyModal } from '../../components/modals/physicalCardApplyModal.jsx';
import { PincodeModal } from '../../components/modals/pincodeModal.jsx';

export default function Cards() {
	const hasPincode = localStorage.getItem('hasPincode');

	const [cards, setCards] = useState([]);

	const [cardTemplates, setCardTemplates] = useState([]);

	const [isLoading, setIsLoading] = useState(false);

	const [modalState, setModalState] = useState({
		isVirtualApplyModalOpen: false,
		isVirtualSuccessModalOpen: false,
		isPhysicalSuccessModalOpen: false,
		isActionModalOpen: false,
		isPincodeModalOpen: false,
		isPhysicalApplyModalOpen: false,
		isComingSoonModalOpen: false,
	});

	const [selectedCard, setSelectedCard] = useState({});

	useEffect(() => {
		const fetchData = async () => {
			setIsLoading(true);

			const response = await getUserCardsAPI();

			setCards(response.cards);
			setCardTemplates(response.templates);

			setIsLoading(false);
		};

		fetchData();
	}, []);

	const handleModalStateChange = (key, value) => {
		setModalState((prevState) => ({
			...prevState,
			[key]: value,
		}));
	};

	const handleCardApplySubmit = async (formData) => {
		if (!formData.agreeToPrivacyPolicy || !formData.agreeToTerms) {
			return {
				isSuccess: false,
				error: 'Please accept the terms & conditions',
			};
		}

		let shippingData = null;
		const isApplyingCard = localStorage.getItem('applyCard');

		if (isApplyingCard === 'true') {
			window.location.reload();
			return;
		}

		localStorage.setItem('applyCard', true);

		if (selectedCard.type === 'physical')
			shippingData = {
				city: formData.city,
				street: formData.street,
				number: formData.number,
				postCode: formData.postalCode,
				country: formData.country,
				method: formData.method,
			};

		const response = await cardApplyAPI(selectedCard._id, { shippingData });

		localStorage.setItem('applyCard', false);

		if (!response.isSuccess) return { error: response.error };

		if (selectedCard.type === 'virtual') {
			handleModalStateChange('isVirtualSuccessModalOpen', true);
		} else {
			handleModalStateChange('isPhysicalSuccessModalOpen', true);
		}
		handleModalStateChange('isVirtualApplyModalOpen', false);
	};

	const handlePincodeSubmit = async (pincode) => {
		if (hasPincode === 'true') {
			const response = await verifyUserPincode(pincode);

			if (response.isSuccess) {
				handleModalStateChange('isPincodeModalOpen', false);
				handleModalStateChange('isActionModalOpen', true);
			} else return { error: response.error };
		} else {
			const response = await setUserPincode(pincode);

			if (!response.isSuccess) return { error: response.error };

			handleModalStateChange('isPincodeModalOpen', false);
			localStorage.setItem('hasPincode', true);
		}
	};

	return isLoading ? (
		<Loader />
	) : (
		<div>
			<Header />

			<h1 className="font-bolder text-2xl mb-5 text-center p-5">Virtual & Physical Cards</h1>

			<h1 className="font-bolder text-xl text-center">Active Cards</h1>
			<div className="flex flex-col items-center justify-center">
				<div className="grid grid-cols-1 xl:w-[50%] sm:grid-cols-2 gap-4 w-full overflow-x-auto">
					{cards &&
						cards.length > 0 &&
						cards.map((card) => (
							<CreditCardItem
								key={card._id}
								card={card}
								cardActionsModalHandler={() => {
									setSelectedCard(card);
									handleModalStateChange('isPincodeModalOpen', true);
								}}
							/>
						))}
				</div>
				{cards.length === 0 && <p className="">You didn't applied for any card yet.</p>}
			</div>

			<h1 className="font-bolder text-xl mb-5 text-center">Order Cards</h1>

			<div className="flex items-center justify-center">
				<div className="grid grid-cols-1 sm:grid-cols-2 xl:w-[50%] gap-4 w-full overflow-x-auto">
					{cardTemplates &&
						cardTemplates.length > 0 &&
						cardTemplates.map((template) => (
							<CreditCardTemplateItem
								key={template._id}
								card={template}
								cardApplyModalHandler={() => {
									setSelectedCard(template);
									handleModalStateChange(template.type === 'virtual' ? 'isVirtualApplyModalOpen' : 'isPhysicalApplyModalOpen', true);
								}}
							/>
						))}
				</div>
			</div>

			{modalState.isVirtualApplyModalOpen && (
				<VirtualCardApplyModal
					isOpen={modalState.isVirtualApplyModalOpen}
					onClose={() => handleModalStateChange('isVirtualApplyModalOpen', false)}
					onCardApplySubmit={async (formData) => {
						return handleCardApplySubmit(formData);
					}}
					cardPrice={selectedCard.currency.symbol + selectedCard.cost}
				/>
			)}

			{modalState.isPhysicalApplyModalOpen && (
				<PhysicalCardApplyModal
					isOpen={modalState.isPhysicalApplyModalOpen}
					onClose={() => handleModalStateChange('isPhysicalApplyModalOpen', false)}
					onCardApplySubmit={(formData) => {
						return handleCardApplySubmit(formData);
					}}
					cardPrice={selectedCard.currency.symbol + selectedCard.cost}
				/>
			)}

			{modalState.isVirtualSuccessModalOpen && (
				<CustomModal
					isOpen={modalState.isVirtualSuccessModalOpen}
					onClose={() => handleModalStateChange('isVirtualSuccessModalOpen', false)}
					title={'Your card has been issued!'}
					subTitle={'An email sent to you with the card details'}
					children={
						<div className="flex justify-center">
							<PrimaryButton
								onClick={() => {
									handleModalStateChange('isVirtualSuccessModalOpen', false);
									window.location.reload();
								}}
								text="Close"
								className="p-5 mt-5"
							/>
						</div>
					}
				/>
			)}

			{modalState.isPhysicalSuccessModalOpen && (
				<CustomModal
					isOpen={modalState.isPhysicalSuccessModalOpen}
					onClose={() => handleModalStateChange('isPhysicalSuccessModalOpen', false)}
					title={'Your card has been issued!'}
					subTitle={'Details has been sent to your messages & updates inbox'}
					children={
						<div className="flex justify-center">
							<PrimaryButton
								onClick={() => {
									handleModalStateChange('isPhysicalSuccessModalOpen', false);
									window.location.reload();
								}}
								text="Close"
								className="p-5 mt-5"
							/>
						</div>
					}
				/>
			)}

			{modalState.isActionModalOpen && (
				<CardActionsModal
					isOpen={modalState.isActionModalOpen}
					onClose={() => handleModalStateChange('isActionModalOpen', false)}
					selectedCard={selectedCard}
				/>
			)}

			{modalState.isPincodeModalOpen && (
				<PincodeModal
					isOpen={modalState.isPincodeModalOpen}
					onClose={() => handleModalStateChange('isPincodeModalOpen', false)}
					onAction={async (pincode) => await handlePincodeSubmit(pincode)}
					buttonText={hasPincode === 'true' ? 'Verify' : 'Set'}
				/>
			)}

			{modalState.isComingSoonModalOpen && (
				<CustomModal
					isOpen={modalState.isComingSoonModalOpen}
					onClose={() => handleModalStateChange('isComingSoonModalOpen', false)}
					title={'Will be available soon!'}
					subTitle={'This action will be available soon'}
					children={
						<div className="flex justify-center">
							<PrimaryButton
								onClick={() => {
									handleModalStateChange('isComingSoonModalOpen', false);
								}}
								text="Close"
								className="p-5 mt-5"
							/>
						</div>
					}
				/>
			)}
		</div>
	);
}
