import { apiRequest } from './api';

export async function updateUserPassword(data) {
	try {
		await apiRequest('/user/profile/password', 'PATCH', data);
		return { isSuccess: true };
	} catch (error) {
		return { isSuccess: false, error: error.message };
	}
}

export async function getUserTransactionsAPI() {
	try {
		const response = await apiRequest('/user/transactions', 'GET', null);
		return { isSuccess: true, data: response.data };
	} catch (error) {
		return { isSuccess: false, error: error.message };
	}
}
