import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import useValidation from '../../hooks/useValidation';

import Loader from '../utils/loader';

import { getStorageToken } from '../../utils/functions/token';
import { registerSchema, registerConfirmSchema } from '../../utils/validations/user-schema';

import { register, registerConfirm } from '../../services/auth';

import { Input } from '../../components/inputs/text-input';
import { PrimaryButton } from '../../components/buttons/primary-button';
import { SecondaryButton } from '../../components/buttons/secondary-button';
import { Header } from '../../components/navigation/header';
import IntercomNonUserChat from '../../components/chats/intercom_non_user_chat';
import PasswordInput from '../../components/inputs/password-input';
import { PincodeInput } from '../../components/inputs/pincodeInput';

export default function Register() {
	const navigate = useNavigate();

	const { t } = useTranslation();

	const currentDirection = localStorage.getItem('languageDir');

	const [isLoading, setIsLoading] = useState(false);

	const [nextPage, setNextPage] = useState(false);

	const { validationErrMsg: otpValidationErrMsg, validate: otpValidate } = useValidation(registerSchema(t));

	const { validationErrMsg, validate } = useValidation(registerConfirmSchema(t));

	const [apiErrMsg, setApiErrMsg] = useState('');

	const [pincodeValues, setPincodeValues] = useState(Array(6).fill(''));

	const [formData, setFormData] = useState({
		firstName: '',
		lastName: '',
		email: '',
		otp: '',
		password: '',
		affiliateCode: '',
		type: 'individual',
		confirmPassword: '',
		agreeToTerms: false,
		agreeToPrivacyPolicy: false,
	});

	const location = useLocation();

	useEffect(() => {
		const params = new URLSearchParams(location.search);
		const affiliateCode = params.get('affiliateCode');
		if (affiliateCode) {
			setFormData((prevState) => ({ ...prevState, affiliateCode }));
		}
	}, [location]);

	useEffect(() => {
		const token = getStorageToken();

		if (token) {
			navigate('/');
		}
	}, [navigate]);

	const handleCheckboxChange = (fieldName) => {
		setFormData((data) => ({ ...data, [fieldName]: !data[fieldName] }));
	};

	const handleRegister = async (event) => {
		event.preventDefault();

		const data = {
			type: formData.type,
			email: formData.email,
		};

		const isValid = otpValidate(data);
		if (!isValid) return;

		if (!isLoading) {
			setIsLoading(true);

			const response = await register(formData.type, formData.email);

			if (!response.isSuccess) {
				setApiErrMsg(t(`register.serverErrors.${response.error}`));
			} else {
				setApiErrMsg('');
				setNextPage(true);
			}
		}
		setIsLoading(false);
	};

	const handleRegisterConfirm = async (event) => {
		event.preventDefault();

		const data = {
			email: formData.email,
			type: formData.type,
			otp: pincodeValues.join(''),
			password: formData.password,
			confirmPassword: formData.confirmPassword,
			affiliateCode: formData.affiliateCode,
		};

		const isValid = validate(data);
		if (!isValid) return;

		if (!formData.agreeToTerms || !formData.agreeToPrivacyPolicy) {
			setApiErrMsg(t('register.errors.termsAndPrivacyRequired'));
			setIsLoading(false);
			return;
		}

		if (!isLoading) {
			setIsLoading(true);

			const response = await registerConfirm(data);

			if (!response.isSuccess) {
				setApiErrMsg(response.error);
			} else {
				setApiErrMsg('');
				navigate(0);
			}
			setIsLoading(false);
		}
	};

	return isLoading ? (
		<Loader />
	) : (
		<div>
			<Header showLogo={!formData.affiliateCode} showHome={false} showUser={false} showBalance={false} showBack={false} />
			<div dir={currentDirection} className="flex flex-col items-center mt-12 p-5">
				<h1 className="font-bolder text-3xl mb-10">{t('register.title')}</h1>
				{!nextPage ? (
					<form onSubmit={handleRegister} className="w-[20rem] flex flex-col items-start">
						<label className="">{t('register.emailInputLabel')}</label>
						<Input
							placeholder={t('register.emailPlaceholder')}
							onChange={(value) => {
								setFormData((data) => ({ ...data, email: value.trim() }));
							}}
							type="text"
							value={formData.email}
							className="bg-secondary border-2 border-black inline-block text-black placeholder:text-black"
						/>
						{/* <SelectInput
							onChange={(value) => {
								setFormData((data) => ({
									...data,
									type: value,
								}));
							}}
							defaultValue={{ value: 'individual', label: t('register.typeIndividualOption') }}
							// placeholder="Select Type"
							options={[
								{ value: 'individual', label: t('register.typeIndividualOption') },
								{ value: 'merchant', label: t('register.typeBusinessOption') },
							]}
							className="w-full"
						/> */}

						<div className="flex flex-col justify-center items-center w-full p-5">
							{apiErrMsg && <div className="text-error mb-5 font-bold">{apiErrMsg}</div>}
							{otpValidationErrMsg && <div className="text-error mb-5 font-bold">{otpValidationErrMsg}</div>}

							<PrimaryButton text={t('register.continueButton')} className="p-10" />
							{!formData.affiliateCode && (
								<SecondaryButton text={t('register.loginButton')} className="p-5 mt-3 mb-3" onClick={() => navigate('/')} />
							)}
						</div>
					</form>
				) : (
					<form dir={currentDirection} onSubmit={handleRegisterConfirm} className="flex flex-col justify-center items-center mb-10">
						<p className="text-xl mb-5 text-center">
							{t('register.otpTitleText')} {formData.email}
						</p>

						<label>{t('register.otpLabel')}</label>

						<div dir="ltr" className="w-[90%]">
							<PincodeInput onChange={(value) => setPincodeValues(value)} pincode={pincodeValues} className="bg-secondary text-primary" />
						</div>

						{formData.affiliateCode && (
							<div className="flex flex-col items-center w-full justify-center">
								<p className="">{t('register.affiliateLabel')}</p>
								<Input
									disabled={true}
									placeholder="Affiliate Code"
									type="text"
									value={formData.affiliateCode}
									className="bg-secondary border-2 border-black inline-block text-black placeholder:text-black"
								/>
							</div>
						)}
						<label>{t('register.passwordLabel')}</label>

						<PasswordInput
							placeholder={t('register.passwordPlaceholder')}
							value={formData.password}
							onChange={(value) => {
								setFormData((data) => ({
									...data,
									password: value,
								}));
							}}
						/>

						<PasswordInput
							value={formData.confirmPassword}
							placeholder={t('register.passwordConfirmPlaceholder')}
							onChange={(value) => {
								setFormData((data) => ({
									...data,
									confirmPassword: value,
								}));
							}}
						/>

						<p className="text-center mb-5 w-[80%]">
							<u>{t('register.passwordInstructionsTitle')}</u>
							<br />
							{t('register.passwordInstructions')}
						</p>

						<div className="flex w-full justify-center">
							<input
								type="checkbox"
								id="termsCheckbox"
								checked={formData.agreeToTerms}
								onChange={() => handleCheckboxChange('agreeToTerms')}
								className="me-2"
							/>
							<label htmlFor="termsCheckbox" className="ml-2">
								{t('termsAndConditions.title')}
								<span className="text-primary cursor-pointer m-2" onClick={() => window.open('/terms', '_blank')}>
									{t('termsAndConditions.terms')}
								</span>
							</label>
						</div>

						<div className="flex w-full justify-center">
							<input
								type="checkbox"
								id="privacyCheckbox"
								checked={formData.agreeToPrivacyPolicy}
								onChange={() => handleCheckboxChange('agreeToPrivacyPolicy')}
								className="me-2"
							/>
							<label htmlFor="privacyCheckbox" className="ml-2">
								{t('termsAndConditions.title')}
								<span className="text-primary cursor-pointer m-2" onClick={() => window.open('/privacy', '_blank')}>
									{t('termsAndConditions.privacy')}
								</span>
							</label>
						</div>

						<div className="flex flex-col justify-center items-center w-full p-5">
							{apiErrMsg && <div className="text-error mb-5 font-bold">{apiErrMsg}</div>}
							{validationErrMsg && <div className="text-error mb-5 font-bold">{validationErrMsg}</div>}

							<PrimaryButton text={t('register.signupButton')} className="p-10" />
							<SecondaryButton
								text={t('register.backButton')}
								className="p-5 mt-3 mb-3"
								onClick={() => {
									setNextPage(false);
									setApiErrMsg('');
								}}
							/>
						</div>
					</form>
				)}
			</div>
			<IntercomNonUserChat />
		</div>
	);
}
