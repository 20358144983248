import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ExitIcon, AvatarIcon, Cross2Icon, HamburgerMenuIcon } from '@radix-ui/react-icons';
import { logout } from '../../services/auth';
import { getDecodedToken } from '../../utils/functions/token';

const versionNumber = process.env.REACT_APP_APP_VERSION;

export const DropdownUserMenu = () => {
	const navigate = useNavigate();
	const decodedToken = getDecodedToken();
	const [isOpen, setIsOpen] = useState(false);

	const toggleMenu = () => {
		setIsOpen(!isOpen);
	};

	const handleNavigation = (path) => {
		navigate(path);
		setIsOpen(false);
	};

	const handleLogout = () => {
		logout();
		setIsOpen(false);
	};

	return (
		<>
			<div onClick={toggleMenu} className="cursor-pointer p-2">
				<div className="flex flex-col items-center">
					<HamburgerMenuIcon height={40} width={40} className="text-primary" />
				</div>
			</div>

			<div
				className={`fixed top-0 right-0 w-[250px] h-full bg-white shadow-lg transition-all duration-300 z-40 ${
					isOpen ? 'visible' : 'invisible'
				} flex flex-col`}
			>
				<div className="p-5 flex-grow">
					<div className="flex justify-end">
						<button onClick={toggleMenu} className="text-primary mb-4">
							<Cross2Icon height={20} width={20} className="text-primary" />
						</button>
					</div>
					<p className="text-primary">Welcome, {decodedToken.firstName + ' ' + decodedToken.lastName}</p>
					<p className="mb-5 text-primary">{decodedToken.email}</p>
					<div
						onClick={() => handleNavigation('/profile')}
						className="group text-[16px] leading-none text-primary rounded-[3px] flex items-center h-[40px] px-[5px] relative cursor-pointer"
					>
						Profile
						<div className="ml-auto pl-[20px] text-primary group-data-[highlighted]:text-white group-data-[disabled]:text-primary">
							<AvatarIcon height={20} width={20} className="text-primary" />
						</div>
					</div>
					<div
						onClick={handleLogout}
						className="group text-[16px] leading-none text-primary rounded-[3px] flex items-center h-[40px] px-[5px] relative cursor-pointer"
					>
						Logout
						<div className="ml-auto pl-[20px] text-primary group-data-[highlighted]:text-white group-data-[disabled]:text-gray-500">
							<ExitIcon height={20} width={20} className="text-primary" />
						</div>
					</div>
				</div>
				<p className="flex p-5 mb-20 text-primary mt-auto">v{versionNumber}</p>
			</div>
		</>
	);
};
