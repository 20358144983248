export const PrimaryButton = ({ onClick, text, disabled = false, className, icon }) => {
	return (
		<button
			type="submit"
			disabled={disabled}
			onClick={onClick}
			className={`${
				disabled ? 'bg-gray- first-letter:' : 'bg-primary'
			} active:scale-[.98] active:duration-75 transition-all hover:scale-[1.01] ease-in-out py-3 rounded-xl text-white text-lg font-bold max-w-md ${className}`}
		>
			<div className="flex justify-center">
				{text}
				{icon ?? ''}
			</div>
		</button>
	);
};
