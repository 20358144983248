export default function Stepper({ steps, currentStepIndex = 0 }) {
	return (
		<div className="flex items-center justify-center mb-5">
			<div className="flex items-center">
				{steps.map((step, index) => (
					<div key={index} className="relative z-10 flex flex-col items-center">
						<div
							className={`w-10 h-10 flex items-center justify-center ${
								currentStepIndex === index ? 'bg-primary' : 'bg-secondary'
							} text-white rounded-full`}
						>
							{index + 1}
						</div>

						{/* Step label */}
						<div className="mt-2 text-sm text-primary font-bold ms-5">{step}</div>

						{/* Add margin between each circle */}
						{index < steps.length - 1 && <div className="w-10"></div>}
					</div>
				))}
			</div>
		</div>
	);
}
