import { apiRequest } from './api';

export const createVerestroIbanAPI = async () => {
	try {
		const response = await apiRequest('/user/ibans', 'POST');

		return { isSuccess: true, data: response.data };
	} catch (error) {
		return { isSuccess: false, error: error.message };
	}
};

export const getVerestroIbanAPI = async () => {
	try {
		const response = await apiRequest('/user/ibans', 'GET');

		return { isSuccess: true, data: response.data };
	} catch (error) {
		return { isSuccess: false, error: error.message };
	}
};
