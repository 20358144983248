import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import Loader from '../utils/loader';
import { TranferSchema } from '../../utils/validations/user-schema';

import { getFeeByType } from '../../services/fees';
import { transferToUserAPI } from '../../services/transactions';

import { Header } from '../../components/navigation/header';
import { Input } from '../../components/inputs/text-input';
import { SecondaryButton } from '../../components/buttons/secondary-button';
import SelectInput from '../../components/inputs/select-input';
import useValidation from '../../hooks/useValidation';
import { useSelector } from 'react-redux';

export default function MemberTransfer() {
	const navigate = useNavigate();

	const { userWallets } = useSelector((state) => state.accounts);

	const { currencyCode } = useParams();

	const [currency, setCurrency] = useState(userWallets[0].currency);

	const [amount, setAmount] = useState();
	const [customerNumber, setCustomerNumber] = useState('');
	const [customerName, setCustomerName] = useState('');
	const [reason, setReason] = useState('');
	const [errorMsg, setErrorMsg] = useState('');

	const [isLoading, setIsLoading] = useState(false);

	const [totalFee, setTotalFee] = useState(0);

	const { validationErrMsg, validate } = useValidation(TranferSchema);

	useEffect(() => {
		if (currencyCode && currencyCode !== 'EUR' && currencyCode !== 'USD') {
			navigate('/404', { replace: true });
		}
	}, [currencyCode]);

	useEffect(() => {
		const fetchData = async () => {
			const feesResponse = await getFeeByType({ type: 'transfer' });
			if (feesResponse.isSuccess) setTotalFee(feesResponse.data.cost);
		};

		fetchData();
	}, []);

	const transfer = async () => {
		const data = {
			customerNumber,
			customerName,
			currency: currencyCode ?? currency,
			amount: parseFloat(amount),
			reason,
		};

		const isValid = validate(data);
		if (!isValid) return;

		setErrorMsg('');

		if (!isLoading) {
			setIsLoading(true);

			const response = await transferToUserAPI(data);

			if (!response.isSuccess) {
				setIsLoading(false);
				setErrorMsg(response.error ?? 'Please make sure you have enough balance & wallet member id is correct!');
				setReason('');
				return;
			}

			setIsLoading(false);
			navigate('/');
		}
	};

	return isLoading ? (
		<Loader />
	) : (
		<div>
			<Header />
			<div className="flex flex-col items-center pb-20">
				<h1 className="font-bolder text-3xl xsm:text-[2rem] mb-5 text-center">Paytora Member Transfer</h1>
				<p className="mb-5 text-red-500 text-center">
					** Please be advised that any transfer can be only to family member in first level <br /> (Mother,Father, Wife, Son, Daughter, Brother,
					Sister)
				</p>

				<div className="flex justify-center">
					<div>
						{currencyCode ? (
							<p className="font-bolder mb-5 text-xl">Transfer {currencyCode} to a paytora member</p>
						) : (
							<SelectInput
								onChange={(value) => setCurrency(value)}
								options={userWallets
									.filter((wallet) => wallet.currency !== 'GBP')
									.map((wallet) => ({ value: wallet.currency, label: wallet.currency }))}
								defaultValue={{ label: userWallets[0].currency, value: userWallets[0].currency }}
							/>
						)}

						<p className="text-center mb-2">
							<b>Your available amount is: </b>
							{(() => {
								const wallet = userWallets.find((wallet) => wallet.currency === (currencyCode ?? currency));
								return wallet ? `${wallet.balance}${wallet.symbol}` : '0.00';
							})()}
						</p>

						<label className="flex justify-center">Transfer Amount</label>

						<Input
							placeholder="Transfer Amount"
							type="number"
							value={amount}
							onChange={(value) => setAmount(value)}
							className={`bg-secondary border-2 border-black inline-block ${totalFee > 0 && amount > 0 ? 'mb-0' : 'mb-10'}`}
							inputMode="numeric"
							pattern="[0-9]"
						/>

						<div className="text-center mb-4 font-bolder">
							{totalFee > 0 && amount > 0 && <p className="mb-1 mt-1 text-red-500">{parseInt(amount) - totalFee} will be transfered</p>}
						</div>
						<label className="flex justify-center">Paytora Account Number</label>
						<Input
							// placeholder="Paytora Account Number"
							onChange={(value) => {
								setCustomerNumber(value);
							}}
							type="text"
							value={customerNumber}
							className="bg-secondary border-2 border-black inline-block"
						/>
						<label className="flex justify-center">Paytora Full Name (ex. John Doe)</label>
						<Input
							onChange={(value) => {
								setCustomerName(value);
							}}
							type="text"
							value={customerName}
							className="bg-secondary border-2 border-black inline-block"
						/>
						<SelectInput
							onChange={setReason}
							options={[
								{ label: 'Family Support', value: 'Family Support' },
								{ label: 'Gift', value: 'Gift' },
								{ label: 'Loan', value: 'Loan' },
							]}
							defaultValue={{ label: 'Select A Reason', value: '' }}
						/>
					</div>
				</div>
				<p className="text-red-500 font-bolder">{errorMsg}</p>
				{validationErrMsg && <p className="text-red-500 font-bolder">{validationErrMsg}</p>}
				<SecondaryButton text="Send" className="p-5" onClick={transfer} />
			</div>
		</div>
	);
}
