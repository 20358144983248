import React, { useState } from 'react';
import Modal from 'react-modal';
import { PrimaryButton } from '../buttons/primary-button';
import Loader from '../../pages/utils/loader';
import { PincodeInput } from '../inputs/pincodeInput';

export const PincodeModal = ({ isOpen, onClose, onAction, buttonText, subTitle }) => {
	const [pincodeValues, setPincodeValues] = useState(Array(6).fill(''));

	const [errMsg, setErrMsg] = useState('');
	const [isLoading, setIsLoading] = useState(false);

	const customStyles = {
		content: {
			top: '50%',
			left: '50%',
			right: 'auto',
			bottom: 'auto',
			marginRight: '-50%',
			transform: 'translate(-50%, -50%)',
			width: '400px',
		},
	};

	return (
		<Modal isOpen={isOpen} onRequestClose={onClose} ariaHideApp={false} style={customStyles}>
			{isLoading ? (
				<Loader className="h-[20rem]" />
			) : (
				<div>
					<h2 className="text-2xl text-center">Pincode</h2>
					<div className="flex flex-col items-center">
						<p className="text-red-500 text-center mt-1 mb-2">{subTitle}</p>

						<PincodeInput onChange={(value) => setPincodeValues(value)} pincode={pincodeValues} className="text-white" />

						<PrimaryButton
							onClick={async () => {
								const pincode = pincodeValues.join('');
								setIsLoading(true);
								const response = await onAction(pincode);
								setIsLoading(false);
								if (response && response.error) setErrMsg(response.error);

								setPincodeValues(Array(6).fill(''));
							}}
							text={buttonText}
							className="p-10 mt-4"
						/>
						<PrimaryButton
							onClick={() => {
								onClose();
								setPincodeValues(Array(6).fill(''));
							}}
							text={'Close'}
							className="p-10 mt-4"
						/>
						<p className="text-red-500 font-bolder text-center mt-2">forgot your pincode? please chat with us!</p>
						<p className="text-red-500">{errMsg}</p>
					</div>
				</div>
			)}
		</Modal>
	);
};
