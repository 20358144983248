import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { getMessagesAPI, updateUnreadMessagesAPI } from '../../services/messages';

import { convertDateToString } from '../../utils/functions/date';

import { Header } from '../../components/navigation/header';

export default function Messages() {
	const location = useLocation();
	const { hasMessages } = location.state || false;

	const [messages, setMessages] = useState([]);

	useEffect(() => {
		const fetchData = async () => {
			const userMessages = await getMessagesAPI();
			setMessages(userMessages.data);

			if (hasMessages) {
				await updateUnreadMessagesAPI();
			}
		};

		fetchData();
	}, [hasMessages]);

	return (
		<div>
			<Header />
			<div className="flex justify-center">
				<h1 className="font-bolder text-2xl mt-10 mb-10">Messages and Updates</h1>
			</div>
			{/* <div className="flex justify-center w-full">
				<button className="flex-col flex items-center" onClick={() => navigate('/admin-message')}>
					<img src="/assets/support_agent.png" alt="support" className="" />
					<button className="bg-primary p-10 text-white m-10 text-xl" onClick={() => navigate('/admin-message')}>
						Send us a message
					</button>
				</button>
			</div> */}
			<div className="flex-col">
				{messages.length > 0 ? (
					messages.map((message) => (
						<div className="mb-5 p-2" key={message._id}>
							<div className="flex justify-between text-primary p-2">
								<h1>{convertDateToString(message.createdAt)}</h1>
								{message.sender ? message.sender.firstName + ' ' + message.sender.lastName : ''}
							</div>
							<div className="bg-secondary flex items-center w-full p-5">
								<img src="/assets/message.png" alt="logo" className="w-10 me-5" />

								<h1 className="w-[100%]">{message.content}</h1>
							</div>
						</div>
					))
				) : (
					<div className="text-center text-2xl p-10">No Messages Yet</div>
				)}
			</div>
		</div>
	);
}
