import { z } from 'zod';
import { validationErrors } from '../text/errors.text';

export const loginSchema = (t) =>
	z.object({
		email: z.string().email({ message: t('login.errors.invalidEmail') }),
		password: z
			.string()
			.trim()
			.min(1, { message: t('login.errors.requiredPassword') }),
	});

export const registerConfirmSchema = (t) =>
	z
		.object({
			otp: z
				.string()
				.trim()
				.min(4, { message: t('register.errors.invalidOTP') }),
			password: z
				.string()
				.trim()
				.min(1, { message: t('register.errors.requiredPassword') })
				.regex(/^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[$&+,:;=?@#|'<>.^*()%!-]).{8,}$/, {
					message: t('register.passwordInstructions'),
				}),
			confirmPassword: z
				.string()
				.trim()
				.min(1, { message: t('register.errors.requiredConfirmPassword') }),
		})
		.refine((data) => data.password === data.confirmPassword, {
			message: t('register.errors.passwordsNotMatch'),
		});

export const registerSchema = (t) =>
	z.object({
		email: z.string().email({ message: t('register.errors.invalidEmail') }),
		type: z
			.string()
			.trim()
			.min(1, { message: t('register.errors.invalidType') }),
	});

export const forgotPasswordConfirmSchema = z
	.object({
		email: z.string().email({ message: 'Email is not valid!' }),
		password: z
			.string()
			.trim()
			.min(1, { message: 'Password can not be empty!' })
			.regex(/^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[$&+,:;=?@#|'<>.^*()%!-]).{8,12}$/, {
				message: validationErrors.password_regex,
			}),
		confirmPassword: z.string().trim().min(1, { message: 'Confirm password can not be empty!' }),
	})
	.refine((data) => data.password === data.confirmPassword, {
		message: validationErrors.password_not_match,
	});

export const forgotPasswordSchema = z.object({
	email: z.string().email({ message: 'Email is not valid!' }),
});

export const TranferSchema = z.object({
	currency: z.string().trim().min(3, { message: 'Please select currency!' }),
	amount: z.number().min(1, { message: 'Please enter a valid amount' }),
	customerNumber: z.string().trim().min(1, { message: 'Account number is required!' }),
	customerName: z.string().trim().min(1, { message: 'Customer Name is Required!' }),
	reason: z.string().trim().min(1, { message: 'Please add a reason to transfer!' }),
});

export const SwapSchema = z
	.object({
		fromCurrency: z.string().trim().min(3, { message: 'Please select from currency!' }),
		amount: z.number().min(1, { message: 'Please enter a valid amount' }),
		toCurrency: z.string().trim().min(3, { message: 'Please select to currency!' }),
	})
	.refine((data) => data.fromCurrency !== data.toCurrency, {
		message: 'From currency and exchanged currency must be different!',
		path: ['toCurrency'],
	});

export const bankWithdrawSchema = z.object({
	bankCountry: z.string().trim().min(1, { message: 'Please select bank country!' }),
	bankCity: z.string().trim().min(1, { message: 'Please select bank city!' }),
	bankName: z.string().trim().min(1, { message: 'Please select bank name!' }),
	bankAddress: z.string().trim().min(1, { message: 'Please select bank address!' }),
	bankZipCode: z.string().trim().min(1, { message: 'Please select bank zipcode!' }),
	bankAccountNumber: z.string().trim().min(1, { message: 'Please select bank account number!' }),
	bankBranchCode: z.string().trim().min(1, { message: 'Please select bank branch code!' }),
	currency: z.string().trim().min(3, { message: 'Please select currency!' }),
	amount: z.number().min(1, { message: 'Please enter a valid amount' }),
	customerName: z.string().trim().min(1, { message: 'Please select customer name!' }),
	bankSwiftOrSepa: z.string().trim().min(1, { message: 'Please select bank swift/sepa!' }),
	bankIBAN: z.string().trim().min(1, { message: 'Please select bank IBAN/Route!' }),
});

export const physicalCardApplySchema = z.object({
	city: z.string().trim().min(1, { message: 'Please enter a city!' }),
	street: z.string().trim().min(1, { message: 'Please enter a street!' }),
	number: z.string().trim().min(1, { message: 'Please select apartment/house Number!' }),
	postalCode: z.string().trim().min(1, { message: 'Please select a postal code!' }),
	country: z.string().trim().min(1, { message: 'Please select a country!' }),
	method: z.string().trim().min(1, { message: 'Please select a shipping method!' }),
});
