import React from 'react';

import { useNavigate } from 'react-router-dom';

const DashboardActionsSection = ({ hasMessages }) => {
	const navigate = useNavigate();

	return (
		<div className="grid grid-cols-1 xsm:grid-cols-2 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-6 gap-4 mb-5 mt-5">
			<button className="p-4 flex-col items-center flex justify-center" onClick={() => navigate('/transfer-request')}>
				<img src={`/assets/dashboard/transfer_request.svg`} alt="logo" className="xsm:w-[100px] " />
				<h1 className="text-center">Sending Money</h1>
			</button>
			<button className="p-4  flex-col items-center flex justify-center" onClick={() => navigate('top-up')}>
				<img src={`/assets/dashboard/topup_wallet.svg`} alt="logo" className="xsm:w-[100px]" />
				<h1 className="text-center">Depositing Money</h1>
			</button>
			<button className=" p-4  flex-col items-center flex justify-center" onClick={() => navigate('swap-currency')}>
				<img src={`/assets/dashboard/swap_currency.svg`} alt="logo" className="xsm:w-[100px]" />
				<h1 className="text-center">SWAP CURRENCY</h1>
			</button>
			<button className="p-4  flex-col items-center flex justify-center" onClick={() => navigate('cards')}>
				<img src={`/assets/dashboard/card_top_up.svg`} alt="logo" className="xsm:w-[100px]" />
				<h1 className="text-center">Cards Deposit/Apply</h1>
			</button>

			<button className="p-4  flex-col items-center flex justify-center" onClick={() => navigate('card-to-card')}>
				<img src={`/assets/dashboard/card-to-card.svg`} alt="logo" className="xsm:w-[100px]" />
				<h1 className="text-center">Card To Card</h1>
			</button>

			<button
				className="p-4 flex-col items-center flex justify-center"
				onClick={() => navigate('/messages', { state: { hasMessages: hasMessages } })}
			>
				<img src={`/assets/dashboard/messages.svg`} alt="logo" className="xsm:w-[100px]" />
				<h1 className="text-center">
					{hasMessages && <span className="inline-block h-3 w-3 bg-red-500 rounded-full ml-2"></span>} Messages & Updates
				</h1>
			</button>
		</div>
	);
};

export default DashboardActionsSection;
