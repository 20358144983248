import React, { useEffect, useState } from 'react';

import SumsubWebSdk from '@sumsub/websdk-react';

import Loader from '../utils/loader';

import { Header } from '../../components/navigation/header';
import { getSumsubKYCTokenAPI, getUserKYCStatusAPI } from '../../services/kyc';

export default function KYCProcess() {
	const [kycToken, setKycToken] = useState('');

	const [isLoading, setIsLoading] = useState(true);

	const getAndUpdateKycStatus = async () => {
		const kycValue = localStorage.getItem('kyc');

		const response = await getUserKYCStatusAPI();

		if (!response.isSuccess) return;

		if (response.data !== kycValue) {
			localStorage.setItem('kyc', response.data);
			window.location.reload();
		}
	};

	useEffect(() => {
		const fetchData = async () => {
			const response = await getSumsubKYCTokenAPI();

			if (!response.isSuccess) return;

			setKycToken(response.token);

			setIsLoading(false);
		};

		fetchData();

		const interval = setInterval(() => {
			getAndUpdateKycStatus();
		}, 10000);

		return () => clearInterval(interval);
	}, []);

	const handleSumSubKYCResponse = (response) => {
		console.log('SumSub KYC Response ====> ', response);
	};

	const handleSumSubKYCError = (error) => {
		console.log('SumSub KYC Error ====> ', error);
	};

	return (
		<div>
			<div>
				<Header showLogout={true} showUser={false} showBalance={false} showHome={false} showBack={false} />
				<div>
					<h1 className="font-bolder text-3xl mb-10 mt-10 text-center">KYC Process</h1>
					{isLoading ? (
						<Loader />
					) : (
						<div className="flex justify-center w-full ">
							<SumsubWebSdk
								accessToken={kycToken}
								expirationHandler={() => {
									console.log(`SUMSUB Expired`);
								}}
								config={{ lang: 'en' }}
								//   options={options}
								onMessage={(message) => handleSumSubKYCResponse(message)}
								onError={(error) => handleSumSubKYCError(error)}
							/>
						</div>
					)}
				</div>
			</div>
		</div>
	);
}
