import React, { useState } from 'react';
import Cards from 'react-credit-cards-2';
import 'react-credit-cards-2/dist/es/styles-compiled.css';
import { PrimaryButton } from '../buttons/primary-button';

const CardPaymentForm = ({ submitHandler = () => {}, buttonText = 'Next', showCustomerNumber = false }) => {
	const [state, setState] = useState({
		number: '',
		expiry: '',
		cvc: '',
		name: '',
		customerNumber: '',
		focus: '',
	});

	const [errMsg, setErrMsg] = useState('');

	const handleInputChange = (evt) => {
		const { name, value } = evt.target;

		// if (name === 'expiry') {
		// 	// Remove non-numeric characters
		// 	let formattedValue = value.replace(/\D/g, '');

		// 	// Limit length to 4 digits
		// 	if (formattedValue.length > 4) {
		// 		formattedValue = formattedValue.slice(0, 4);
		// 	}

		// 	// Format as MM/YY
		// 	if (formattedValue.length >= 3) {
		// 		formattedValue = `${formattedValue.slice(0, 2)}/${formattedValue.slice(2, 4)}`;
		// 	}

		// 	// Validate month and year
		// 	const [month] = formattedValue.split('/');

		// 	const monthIsValid = (month.length === 1 && parseInt(month) === 1) || parseInt(month) === 0 || (month.length === 2 && parseInt(month) <= 12);

		// 	if (monthIsValid || formattedValue.length === 0) {
		// 		setState((prev) => ({ ...prev, [name]: formattedValue }));
		// 	}
		// } else
		if (name === 'number') {
			if (value.length <= 16) setState((prev) => ({ ...prev, [name]: value }));
		} else if (name === 'cvc') {
			if (value.length <= 4) setState((prev) => ({ ...prev, [name]: value }));
		} else {
			setState((prev) => ({ ...prev, [name]: value }));
		}
	};

	const handleInputFocus = (evt) => {
		setState((prev) => ({ ...prev, focus: evt.target.name }));
	};

	const isCardValid = () => {
		const { number, cvc, expiry } = state;

		const isCardNumberValid = number.trim().length === 16;

		const isCvcValid = cvc.trim().length === 3 || cvc.trim().length === 4;

		// const isExpiryValid = expiry.trim().length === 5;

		return isCardNumberValid && isCvcValid; //&& isExpiryValid;
	};

	const handleSubmit = () => {
		const { number, cvc, expiry, customerNumber } = state;
		if (!isCardValid()) {
			setErrMsg('Please make sure all fields are validate');
			return;
		} else if (showCustomerNumber && customerNumber === '') {
			setErrMsg('Please enter customer number');
			return;
		}

		submitHandler({ number, cvc, expiry, customerNumber });
	};

	return (
		<div className="flex flex-col justify-center items-center ">
			<form className="border-primary border-2 w-full scale-[85%] p-5">
				<p className="text-red-500 text-center ">You can only transfer to a card issued by LifeUp!</p>
				<div className="flex">
					<div className="flex-col me-5 w-full">
						<label htmlFor="number">Card Number</label>
						<input
							type="number"
							name="number"
							placeholder="Card Number"
							maxLength="16"
							minLength="16"
							value={state.number}
							onChange={handleInputChange}
							onFocus={handleInputFocus}
							className={`bg-transparent w-full rounded-xl py-2 px-4 max-w-md flex flex-col text-black placeholder:text-black text-center mb-5 border-primary border-2  focus:outline-none`}
						/>
					</div>
					<div className="flex-col">
						<label htmlFor="cvc">CVV</label>
						<input
							type="number"
							name="cvc"
							placeholder="CVC"
							maxLength="4"
							value={state.cvc}
							onChange={handleInputChange}
							onFocus={handleInputFocus}
							className={`bg-transparent w-full rounded-xl py-2 px-4 max-w-md flex flex-col text-black placeholder:text-black text-center mb-5 border-primary border-2  focus:outline-none`}
						/>
					</div>
				</div>
				{showCustomerNumber && (
					<div className="flex flex-col items-center">
						<label htmlFor="customerNumber">Paytora Account Number (ex. E344F3)</label>
						<input
							type="text"
							name="customerNumber"
							// placeholder="ex. E344F3"
							value={state.customerNumber}
							onChange={handleInputChange}
							onFocus={handleInputFocus}
							className={`bg-transparent w-full rounded-xl py-2 px-4 max-w-md flex flex-col text-black placeholder:text-black text-center mb-5 border-primary border-2 focus:outline-none`}
						/>
					</div>
				)}
			</form>
			<Cards number={state.number} expiry={state.expiry} cvc={state.cvc} name="PAYTORA MEMBER" focused={state.focus} />
			<p className="text-red-500 mb-2">{errMsg}</p>
			<PrimaryButton text={buttonText} onClick={handleSubmit} className="p-10 mb-5" />
		</div>
	);
};

export default CardPaymentForm;
