export const SecondaryButton = ({ onClick, text, disabled, className }) => {
	return (
		<button
			disabled={disabled}
			onClick={onClick}
			className={`${
				disabled ? 'bg-gray- first-letter:' : 'bg-secondary'
			} active:scale-[.98] active:duration-75 transition-all hover:scale-[1.01] ease-in-out py-3 rounded-xl text-primary text-lg font-bold max-w-md ${className}`}
		>
			{text}
		</button>
	);
};
