import React, { useState } from 'react';

import { useNavigate } from 'react-router-dom';

import Modal from 'react-modal';

export const CustomModal = ({ isOpen, onClose, title, subTitle, children }) => {
	const customStyles = {
		content: {
			top: '50%',
			left: '50%',
			right: 'auto',
			bottom: 'auto',
			marginRight: '-50%',
			transform: 'translate(-50%, -50%)',
			width: '350px', // Adjust the width as needed
		},
	};
	const navigate = useNavigate();
	const [formData, setFormData] = useState({
		agreeToTerms: false,
		agreeToPrivacyPolicy: false,
	});

	const handleCheckboxChange = (fieldName) => {
		setFormData((data) => ({ ...data, [fieldName]: !data[fieldName] }));
	};

	return (
		<Modal
			isOpen={isOpen}
			onRequestClose={onClose}
			contentLabel={title}
			ariaHideApp={false} // This is to prevent a warning in the console
			style={customStyles}
		>
			<h2 className="text-2xl text-center">{title}</h2>
			<p className="text-xl text-center text-red-500">{subTitle}</p>

			{children}
			{/* {withTerms && (
				<div>
					<div className="flex items-center mt-3">
						<input type="checkbox" id="termsCheckbox" checked={formData.agreeToTerms} onChange={() => handleCheckboxChange('agreeToTerms')} />
						<label htmlFor="termsCheckbox" className="ml-2">
							I agree to the
							<span className="text-primary cursor-pointer m-2" onClick={() => navigate('/terms')}>
								Terms and Conditions
							</span>
						</label>
					</div>

					<div className="flex items-center mt-3 mb-3">
						<input
							type="checkbox"
							id="privacyCheckbox"
							checked={formData.agreeToPrivacyPolicy}
							onChange={() => handleCheckboxChange('agreeToPrivacyPolicy')}
						/>
						<label htmlFor="privacyCheckbox" className="ml-2">
							I agree to the
							<span className="text-primary cursor-pointer m-2" onClick={() => navigate('/privacy')}>
								Privacy Policy
							</span>
						</label>
					</div>
				</div>
			)} */}
			{/* <div className="flex justify-center mt-10">
				<PrimaryButton text={actionButtonText} onClick={onActionButtonClick} className="p-5 me-2" />
				<PrimaryButton onClick={onClose} text="Close" className="p-5" />
			</div> */}
		</Modal>
	);
};
