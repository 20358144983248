import { Header } from '../../components/navigation/header';

const CardPrivacyPolicy = () => {
	return (
		<div>
			<Header showBalance={false} showUser={false} />
			<div className="text-left ms-20 mb-10 w-[80%]">
				<h1 className="font-bolder text-3xl mb-10">
					LifeUp Privacy <span className="text-primary">Policy</span>
				</h1>
				<p>
					<br />
					<br />
					**Privacy Policy for Lifeup International OU**
					<br />
					<br />
					**Address:** Hamelacha 45, Netanya, Israel
					<br />
					<br />
					This privacy policy (the “Policy”) outlines our privacy practices with respect to the Service, including how your personal information and
					data are collected, stored, used, and shared. Providing us with your personal information is a choice you make, and we appreciate and thank
					you for making this choice. You are not legally obligated to provide us with this information, but it is necessary for using the Service.
					<br />
					<br />
					We act as data controllers and are responsible for the collection and use of any data that you submit or provide through the Service.
					<br />
					<br />
					In addition to this privacy statement, we may have additional policies or codes of conduct that will apply to your use of the Service. If
					you have any questions about your use of the Service, please contact us.
					<br />
					<br />
					**1. Information We Collect**
					<br />
					<br />
					When you submit an inquiry, make a customer service request, or register an account, you provide contact information such as your name,
					address, mobile phone number, and email address.
					<br />
					<br />
					We may collect your credit card or debit card information when you pay for the Service. This information is collected on our behalf by a
					payment processor. We may use cookies and similar tracking technologies to collect information on our website and via email. Additionally,
					we may collect your IP address from your internet service provider and may collect your bank details as needed.
					<br />
					<br />
					**2. Use & Disclosure of Collected Information**
					<br />
					<br />
					We may share the information you provide with third-party service providers to support the Service:
					<br />
					<br />
					- Communicating with you regarding the use of the Service and responding to your requests
					<br />
					- Enhancing the security and safety of the Service by investigating suspicious activity or violations of applicable terms or policies
					<br />
					- Developing new tools, products, or services within the Service
					<br />
					- Associating activity on the Service across different devices operated by the same individual to improve overall operation
					<br />
					- Identifying and fixing bugs
					<br />
					- Conducting data and system analytics, including research to improve the Service
					<br />
					- Complying with any applicable law and assisting law enforcement agencies and competent authorities when necessary or justified
					<br />
					<br />
					**3. Accessing, Modifying, Exporting, or Deleting Your Information**
					<br />
					<br />
					You have the following rights with respect to your personal data:
					<br />
					<br />
					- The right to request a copy of your personal data
					<br />
					- The right to request correction of your personal data if inaccurate or outdated
					<br />
					- The right to request deletion of your personal data when it is no longer necessary to retain such data
					<br />
					- The right to withdraw any consent to personal data processing at any time
					<br />
					- The right to request a restriction on further data processing in case of a dispute regarding the accuracy or processing of your personal
					data
					<br />
					- The right to object to the processing of personal data based on legitimate interest and/or direct marketing
					<br />
					<br />
					If you are unable to exercise these rights using the tools provided in the Service, please contact us directly.
					<br />
					<br />
					**4. GDPR Notice**
					<br />
					<br />
					As a data controller of our clients’ personal data, we are committed to protecting and respecting your privacy in compliance with the EU
					General Data Protection Regulation (GDPR) 2016/679.
					<br />
					<br />
					Our legal basis for collecting personal data includes:
					<br />
					<br />
					- Collecting personal data based on consent from the data subject
					<br />
					- Fulfilling our obligations related to contracts and agreements with customers, partners, and suppliers <br />
					- Using personal data if it is considered to be of legitimate interest, provided that the privacy interests of the data subject do not
					override this interest
					<br />
					<br />
					**5. Data Retention**
					<br />
					<br />
					We retain personally identifiable information for as long as we deem necessary for the purposes set forth above. We may delete information
					from our systems, without notice to you, once we deem it no longer necessary for these purposes.
					<br />
					<br />
					**6. CCPA Notice**
					<br />
					<br />
					You may exercise certain consumer rights under the California Consumer Privacy Act (CCPA), including:
					<br />
					<br />
					- The right to know the categories and specific pieces of personal information we have collected about you, the sources of that information,
					the business purpose for collecting it, and the types of third parties we share it with
					<br />
					- The right to request deletion of your personal information, except for information critical to normal business operations
					<br />
					- The right to non-discrimination for exercising your consumer rights
					<br />
					- The right to opt-out of the sale of personal information, although we do not sell any information that identifies you
					<br />
					<br />
					**7. Children's Privacy**
					<br />
					<br />
					This service is for individuals 16 years of age or older. We do not knowingly or intentionally collect information about children under 16
					years of age.
					<br />
					<br />
					**8. Cookies** We may use cookies on the Service to collect statistical information about your use of the Service and enhance your user
					experience. You can block or delete cookies through your browser settings, but this may adversely affect your experience on the Service.
					<br />
					<br />
					**9. Changes to This Privacy Policy**
					<br />
					<br />
					We may change this Policy from time to time. We will notify you of such changes through the Service’s interface. Your continued use of the
					Service constitutes your consent to the amended Policy. If you do not consent to the amended Policy, we may terminate your account and block
					your access to the Service. Immediate changes may be introduced when legally required or necessary. The latest version of the Policy and its
					effective date will always be accessible on the Service.
					<br />
					<br />
					**10. Contact Us**
					<br />
					<br />
					If you have any questions about this Privacy Policy, please contact us at:
					<br />
					<br />
					**Lifeup International OU**
					<br />
					Hamelacha 45, Netanya, Israel
					<br />
					Email: support@lifeupinternational.com
					<br />
					<br />
					Effective date: 30 July 2023
				</p>
			</div>
		</div>
	);
};

export default CardPrivacyPolicy;
