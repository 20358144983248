import React, { useEffect, useRef, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import DashboardActionsSection from './dashboardActionsSection';
import DashboardAccountSection from './dashboardAccountSection';

import { fetchUserAccounts } from '../../store/accountsSlice';

import Loader from '../utils/loader';

import { getUserTransactionsAPI } from '../../services/user';
import { getUnreadMessagesCountAPI } from '../../services/messages';
import { getUserCardsBalanceAPI } from '../../services/cards';

import { Header } from '../../components/navigation/header';
import { Divider } from '../../components/utils/divider';
import IntercomUserChat from '../../components/chats/intercom_user_chat';

export default function Dashboard() {
	const startY = useRef(null);

	const dispatch = useDispatch();
	const { userWallets, totalBalance } = useSelector((state) => state.accounts);

	const [transactions, setTransactions] = useState([]);

	const [cardsBalance, setCardsBalance] = useState(0);

	const [hasMessages, setHasMessages] = useState(false);

	const [isLoading, setIsLoading] = useState(false);

	const loadDashboard = async () => {
		const userTransactions = await getUserTransactionsAPI();
		setTransactions(userTransactions.data);

		const cardsBalanceResponse = await getUserCardsBalanceAPI();
		if (cardsBalanceResponse.isSuccess) setCardsBalance(cardsBalanceResponse.balance);

		const messagesResponse = await getUnreadMessagesCountAPI();
		if (messagesResponse.isSuccess) setHasMessages(messagesResponse.data > 0);
	};

	useEffect(() => {
		dispatch(fetchUserAccounts());
	}, [dispatch]);

	useEffect(() => {
		const fetchData = async () => {
			setIsLoading(true);
			await loadDashboard();
			setIsLoading(false);
		};

		fetchData();
	}, []);

	useEffect(() => {
		localStorage.setItem('totalBalance', totalBalance);
	}, [totalBalance]);

	const handleTouchStart = (e) => {
		startY.current = e.touches[0].clientY;
	};

	const handleTouchMove = (e) => {
		const currentY = e.touches[0].clientY;
		const scrollY = window.scrollY;

		if (startY.current && currentY > startY.current && scrollY === 0) {
			// User is pulling down from the top of the page
			startY.current = null;
			window.location.reload();
		}
	};

	return isLoading ? (
		<Loader />
	) : (
		<div onTouchMove={handleTouchMove} onTouchStart={handleTouchStart}>
			<Header showBack={false} showHome={false} />
			<DashboardActionsSection hasMessages={hasMessages} />
			<Divider />
			<DashboardAccountSection accounts={userWallets} cardsBalance={cardsBalance} />
			<Divider />
			<div className="mt-20"></div>
			<IntercomUserChat />
		</div>
	);
}
