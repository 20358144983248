import { useNavigate } from 'react-router-dom';

import { Header } from '../../components/navigation/header';
import { useState } from 'react';
import { PrimaryButton } from '../../components/buttons/primary-button';
import { CustomModal } from '../../components/utils/modal';

export default function TopUp() {
	const navigate = useNavigate();
	const [isComingSoonModalOpen, setComingSoonModalOpen] = useState(false);

	return (
		<div>
			<Header />
			<div>
				<h1 className="flex justify-center font-bolder text-3xl xsm:text-[2rem] mb-10 mt-5">Wallet Deposit</h1>
				<div className="w-full flex jutify-center items-center flex-col">
					<div className="flex justify-center p-5 w-full">
						{/* <div className="bg-primary rounded-2xl flex justify-center h-48 w-1/2 p-10 me-5">
						 */}
						<div className="bg-primary rounded-2xl flex justify-center h-48 w-1/2 me-5 m-5">
							<button className="text-white text-2xl text-center" onClick={() => navigate('/top-up/other/select')}>
								Deposit To Your Paytora Digital Account
							</button>
						</div>

						{/* <div className="bg-primary rounded-2xl flex justify-center h-48 w-1/2 m-5">
							<button
								className="text-white text-2xl text-center"
								onClick={() => {
									setComingSoonModalOpen(true);
								}}
							>
								Deposit To Your IBAN Account <br />
							</button>
						</div> */}
					</div>
				</div>
				<CustomModal
					isOpen={isComingSoonModalOpen}
					onClose={() => setComingSoonModalOpen(false)}
					title={'Will be available soon!'}
					subTitle={'This action will be available soon'}
					children={
						<div className="flex justify-center">
							<PrimaryButton
								onClick={() => {
									setComingSoonModalOpen(false);
								}}
								text="Close"
								className="p-5 mt-5"
							/>
						</div>
					}
				/>
			</div>
		</div>
	);
}
