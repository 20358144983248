import { useEffect } from 'react';

import { useNavigate } from 'react-router-dom';

import { Header } from '../../components/navigation/header';

export default function ResultPage({
	isSuccess = false,
	successMessage = 'Your action has been successfully submitted!',
	failureMessage = 'Your action has been declined!',
}) {
	const navigate = useNavigate();

	useEffect(() => {
		const redirectTimer = setTimeout(() => {
			navigate('/');
		}, 3000); // 3 seconds

		return () => {
			clearTimeout(redirectTimer);
		};
	}, [navigate]);

	return (
		<div>
			<Header showBack={false} showBalance={false} showUser={false} showHome={false} showLogout={false} />
			<div className="min-h-screen bg-subBrand flex justify-center items-center">
				{isSuccess ? (
					<div className="bg-white p-6  md:mx-auto">
						<svg viewBox="0 0 24 24" class="text-green-600 w-16 h-16 mx-auto my-6">
							<path
								fill="#7FFF7F"
								d="M12,0A12,12,0,1,0,24,12,12.014,12.014,0,0,0,12,0Zm6.927,8.2-6.845,9.289a1.011,1.011,0,0,1-1.43.188L5.764,13.769a1,1,0,1,1,1.25-1.562l4.076,3.261,6.227-8.451A1,1,0,1,1,18.927,8.2Z"
							></path>
						</svg>
						<div className="text-center text-2xl">
							<p>{successMessage}</p>

							<p className="text-lg">You will redirect to the home page immediantly</p>
						</div>
					</div>
				) : (
					<div className="bg-white p-6  md:mx-auto">
						<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" class="text-red-600 w-16 h-16 mx-auto my-6">
							<circle cx="12" cy="12" r="11" fill="#FF0000" />
							<path
								fill="#FFFFFF"
								d="M19.707 4.293a1 1 0 0 0-1.414 0L12 10.586 5.707 4.293a1 1 0 1 0-1.414 1.414L10.586 12l-6.293 6.293a1 1 0 0 0 1.414 1.414L12 13.414l6.293 6.293a1 1 0 0 0 1.414-1.414L13.414 12l6.293-6.293a1 1 0 0 0 0-1.414z"
							/>
						</svg>

						<div className="text-center text-2xl">
							<p>{failureMessage}</p>

							<p className="text-lg">You will redirect to the home page immediantly</p>
						</div>
					</div>
				)}
			</div>
		</div>
	);
}
