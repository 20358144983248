import React, { useState } from 'react';

import numeral from 'numeral';
import { PrimaryButton } from '../../components/buttons/primary-button';
import { createVerestroIbanAPI } from '../../services/ibans';
import { CustomModal } from '../../components/utils/modal';
import { SecondaryButton } from '../../components/buttons/secondary-button';
import Loader from '../utils/loader';

const DashboardAccountSection = ({ accounts, cardsBalance }) => {
	const hasIban = localStorage.getItem('hasIban');
	const [isLoading, setIsLoading] = useState(false);
	const [isIbanModalOpen, setIsIbanModalOpen] = useState(false);

	const [agreeToTerms, setAgreeToTerms] = useState(false);

	const [errMsg, setErrMsg] = useState('');

	const createVerestroIBAN = async () => {
		// add error when not agreed
		if (!isLoading) {
			setIsLoading(true);
			const response = await createVerestroIbanAPI();
			if (!response.isSuccess) {
				setErrMsg(response.error ?? 'Error creating IBAN please contact support');
				return;
			}
			localStorage.setItem('hasIban', true);
			setIsIbanModalOpen(false);
		}
		setIsLoading(false);
	};

	return (
		<div className="lg:flex flex-col items-center">
			<div>
				{accounts.length === 0 ? (
					<div>No Accounts Yet.</div>
				) : (
					<div className="flex-col">
						{accounts
							.filter((account) => account.currency !== 'GBP')
							.map((account) => {
								const color = account.currency === 'USD' ? 'bg-green-500' : account.currency === 'EUR' ? 'bg-blue' : 'bg-gray-500';

								return (
									<a href={`/transactions/paytora/${account.currency}?balance=${numeral(account.balance).format('0,0.00')}`}>
										<div className="p-2" key={account.sdkFinanceId}>
											<div className="flex items-center ms-5 mb-2 font-bolder">
												<div className={`me-3 w-10 h-10 rounded-full ${color} flex items-center justify-center text-white text-xl`}>
													{account.symbol}
												</div>
												Paytora {account.symbol} Account Balance
											</div>
											<div className={`${color} rounded-3xl p-2 text-center font-bolder text-white`}>
												{account.symbol + numeral(account.balance).format('0,0.00')}
											</div>
										</div>
									</a>
								);
							})}
					</div>
				)}
				<a href={`/transactions/cards?balance=${numeral(cardsBalance).format('0,0.00')}`}>
					<div className="p-2">
						<div className="flex items-center ms-5 mb-2 font-bolder">
							<img alt="small_card" src="/assets/small_card.jpeg" className="w-15 h-10 me-3"></img>
							Your Cards Total Balance
						</div>
						<div className="bg-purple rounded-3xl p-2 text-center font-bolder text-white">{'€' + numeral(cardsBalance).format('0,0.00')}</div>
					</div>
				</a>
				{/* {hasIban === 'true' ? (
					<a href="/transactions/iban">
						<div className="p-2">
							<div className="flex items-center ms-5 mb-2 font-bolder">
								<img alt="bank_icon" src="/assets/bank_icon.svg" className="w-15 h-10 me-3"></img>
								IBAN Account Balance
							</div>
							<div className="bg-brown rounded-3xl p-2 text-center font-bolder text-white">€0.00</div>
						</div>
					</a>
				) : (
					<PrimaryButton text="Apply For IBAN" className="w-full mt-2 mb-2" onClick={() => setIsIbanModalOpen(true)} />
				)} */}
			</div>
			<CustomModal
				isOpen={isIbanModalOpen}
				onClose={() => setIsIbanModalOpen(false)}
				title={'Apply for a new EUR IBAN'}
				subTitle="Please agree the terms and conditions below"
				children={
					isLoading ? (
						<Loader />
					) : (
						<div>
							<div>
								<input type="checkbox" id="termsCheckbox" checked={agreeToTerms} onChange={() => setAgreeToTerms(!agreeToTerms)} className="me-2" />
								<label htmlFor="termsCheckbox" className="ml-2">
									{'I agree to the'}
									<span className="text-primary cursor-pointer m-2" onClick={() => window.open('/iban/terms', '_blank')}>
										{'Terms & Conditions'}
									</span>
								</label>
							</div>
							<div className="flex justify-center">
								<PrimaryButton onClick={createVerestroIBAN} text="Apply" className="p-5 mt-5 me-5" />
								<SecondaryButton onClick={() => setIsIbanModalOpen(false)} text="Close" className="p-5 mt-5" />
							</div>
						</div>
					)
				}
			/>
		</div>
	);
};

export default DashboardAccountSection;
