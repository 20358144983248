// Login.js
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import useValidation from '../../hooks/useValidation';

import { forgotPassword, forgotPasswordConfirm } from '../../services/auth';

import Loader from '../utils/loader';
import { getStorageToken } from '../../utils/functions/token';
import { forgotPasswordConfirmSchema, forgotPasswordSchema } from '../../utils/validations/user-schema';

import { Input } from '../../components/inputs/text-input';
import { Header } from '../../components/navigation/header';
import { PrimaryButton } from '../../components/buttons/primary-button';
import { SecondaryButton } from '../../components/buttons/secondary-button';
import IntercomNonUserChat from '../../components/chats/intercom_non_user_chat';

export default function ForgotPassword() {
	const navigate = useNavigate();

	const { validationErrMsg, validate } = useValidation(forgotPasswordSchema);
	const { validationErrMsg: otpValidationErrMsg, validate: otpValidate } = useValidation(forgotPasswordConfirmSchema);

	const [apiErrMsg, setApiErrorMsg] = useState('');

	const [isLoading, setIsLoading] = useState(false);

	const [nextPage, setNextPage] = useState(false);

	const [formData, setFormData] = useState({
		email: '',
		password: '',
		confirmPassword: '',
	});

	useEffect(() => {
		const token = getStorageToken();

		if (token) {
			navigate('/');
		}
	}, [navigate]);

	const handleForgotPassword = async (event) => {
		event.preventDefault();

		const isValid = validate({ email: formData.email });
		if (!isValid) return;

		if (!isLoading) {
			setIsLoading(true);

			const response = await forgotPassword(formData.email);

			if (!response.isSuccess) {
				setApiErrorMsg(response.error);
			} else {
				setApiErrorMsg('');
				setNextPage(true);
			}
		}
		setIsLoading(false);
	};

	const handleForgotPasswordConfirm = async (event) => {
		event.preventDefault();

		const isValid = otpValidate({
			email: formData.email,
			otp: formData.otp,
			password: formData.password,
			confirmPassword: formData.confirmPassword,
		});

		if (!isValid) return;

		if (!isLoading) {
			setIsLoading(true);

			const response = await forgotPasswordConfirm(formData.email, formData.otp, formData.password);

			if (!response.isSuccess) {
				setApiErrorMsg(response.error);
			} else {
				setApiErrorMsg('');
				navigate('/success');
			}
			setIsLoading(false);
		}
	};

	return isLoading ? (
		<Loader />
	) : (
		<div>
			<Header showHome={false} showUser={false} showBalance={false} showBack={false} />
			<div className="flex flex-col items-center mt-12 p-5">
				<h1 className="font-bolder text-3xl mb-10">Forgot Password</h1>
				{!nextPage ? (
					<form onSubmit={handleForgotPassword} className="w-[20rem] flex flex-col items-start">
						<label>Please enter your email address</label>
						<Input
							placeholder="Email"
							onChange={(value) => {
								setFormData((data) => ({ ...data, email: value }));
							}}
							type="text"
							value={formData.email}
							className="bg-secondary border-2 border-black inline-block text-black placeholder:text-black"
						/>
						<div className="flex flex-col justify-center items-center w-full p-5">
							{validationErrMsg && <div className="text-error mb-5 font-bold">{validationErrMsg}</div>}
							{apiErrMsg && <div className="text-error mb-5 font-bold">{apiErrMsg}</div>}
							{otpValidationErrMsg && <div className="text-error mb-5 font-bold">{otpValidationErrMsg}</div>}

							<PrimaryButton text="Continue" className="p-10" />
							<SecondaryButton text="Login" className="p-5 mt-3 mb-3" onClick={() => navigate('/')} />
						</div>
					</form>
				) : (
					<form onSubmit={handleForgotPasswordConfirm} className="flex flex-col justify-center items-center">
						<p className="text-xl mb-5">*One Time Password Code Verification Has Been Sent To Your Mail: {formData.email}</p>

						<label>One Time Password Code</label>
						<Input
							onChange={(value) => {
								setFormData((data) => ({ ...data, otp: value }));
							}}
							placeholder="One Time Password Number"
							type="text"
							maxLength={6}
							value={formData.otp}
							className="bg-secondary border-2 border-black inline-block text-black placeholder:text-black"
						/>

						<label>Please create a new password</label>
						<Input
							onChange={(value) => {
								setFormData((data) => ({ ...data, password: value }));
							}}
							placeholder="Password"
							type="password"
							value={formData.password}
							className={`bg-secondary border-2 border-black inline-block text-black placeholder:text-black ${
								formData.type !== 'individual' ? 'mt-5' : ''
							}`}
						/>

						<Input
							onChange={(value) => {
								setFormData((data) => ({ ...data, confirmPassword: value }));
							}}
							placeholder="Confirm Password"
							type="password"
							value={formData.confirmPassword}
							className="bg-secondary border-2 border-black inline-block text-black placeholder:text-black"
						/>

						<p className="flex flex-col items-start mb-5 ">
							<u>Instructions to create a password:</u>
							<br />
							Your password must be between 8 and 12 characters that must contain at least one capital letter, one lowercase letter, numbers and a
							special character as shown [# $ ! @ * & %]
						</p>

						<div className="flex flex-col justify-center items-center w-full p-5">
							{validationErrMsg && <div className="text-error mb-5 font-bold">{validationErrMsg}</div>}
							{apiErrMsg && <div className="text-error mb-5 font-bold">{apiErrMsg}</div>}
							{otpValidationErrMsg && <div className="text-error mb-5 font-bold">{otpValidationErrMsg}</div>}

							<PrimaryButton text="RESET PASSWORD" className="p-10" />
						</div>
					</form>
				)}
			</div>
			<IntercomNonUserChat />
		</div>
	);
}
