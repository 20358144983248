import { HiOutlineEye, HiOutlineEyeOff } from 'react-icons/hi';
import { Input } from './text-input';
import { useState } from 'react';

export default function PasswordInput({ value, onChange, placeholder = 'Password' }) {
	const [showPassword, setShowPassword] = useState(false);

	return (
		<div dir="ltr" className="relative w-full max-w-md">
			<Input
				placeholder={placeholder}
				value={value}
				type={showPassword ? 'text' : 'password'}
				onChange={(value) => {
					onChange(value);
				}}
				className="text-white placeholder:text-white"
			/>
			<div className="absolute inset-y-0 right-0 pr-3 pb-4 flex items-center cursor-pointer text-gray-400">
				{showPassword ? <HiOutlineEye onClick={() => setShowPassword(false)} /> : <HiOutlineEyeOff onClick={() => setShowPassword(true)} />}
			</div>
		</div>
	);
}
