import React from 'react';
import { useTranslation } from 'react-i18next';
import SelectInput from '../inputs/select-input';

const languageOptions = [
	{ value: 'en', label: '🇺🇸 EN' },
	{ value: 'he', label: '🇮🇱 HE' },
];

function LanguageSwitcher() {
	const { i18n } = useTranslation();

	const changeLanguage1 = (lng) => {
		i18n.changeLanguage(lng);
		localStorage.setItem('language', lng);
		localStorage.setItem('languageDir', lng === 'he' ? 'rtl' : 'ltr');
		window.location.reload();
	};

	const currentLanguage = languageOptions.find((option) => option.value === i18n.language);

	return (
		<div className="relative inline-block text-left">
			<div className="p-5">
				<SelectInput
					name={'lang-select'}
					onChange={(value) => changeLanguage1(value)}
					defaultValue={{ value: i18n.language, label: currentLanguage.label }}
					options={languageOptions}
					className="w-full"
				/>
			</div>
		</div>
	);
}

export default LanguageSwitcher;
