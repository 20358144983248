import { useState } from 'react';
import { getDecodedToken } from '../../utils/functions/token';

export default function TransactionItem({ transaction }) {
	const [showDetails, setShowDetails] = useState(false);
	const decodedToken = getDecodedToken();

	const userId = decodedToken.userId;

	const isReceiver = (transaction) => transaction.receiver._id === userId;

	return (
		<div
			className="flex flex-col items-center mt-10 mb-10"
			onClick={() => {
				setShowDetails(!showDetails);
				console.log(transaction);
			}}
		>
			<div className="bg-black text-white p-2 flex justify-between xsm:w-[90%] w-[50%] items-center">
				<div>{transaction.type}</div>

				<div>{transaction.amount + transaction.currency}</div>
			</div>
			{showDetails && (
				<div className="bg-primary flex flex-col justify-between xsm:w-[90%] w-[50%] p-5">
					{transaction.receiver &&
						(isReceiver(transaction) ? <div>Sender: {transaction.creator}</div> : <div>Receiver: {transaction.receiver.fullName}</div>)}
					{transaction.status && <div>Status: {transaction.status}</div>}
					{transaction.toAmount && (
						<div>
							Amount:
							{(transaction.type === 'Purchase' && transaction.status === 'reversed' ? '+' : transaction.type === 'Purchase' ? '-' : '') +
								transaction.toAmount +
								transaction.toCurrency}
						</div>
					)}
					{transaction.description && <div> {transaction.description.split(' ').slice(0, 3).join(' ')}</div>}
					{/* {transaction.type === 'Card To Card' && isReceiver(transaction) && 'Fee: ' + transaction.fee + transaction.currency} */}
					{transaction.wireType && <div>Wire Type: {transaction.wireType}</div>}
					<div> {transaction.createdAt}</div>
				</div>
			)}
		</div>
	);
}
